import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useIsSMDown } from '@ankr.com/raas-themes';
import { ArrowRight } from '@ankr.com/ui';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';

import { IBreadcrumbItem, IBreadcrumbsContext } from './BreadcrumbsTypes';
import { useBreadcrumbsStyles } from './useBreadcrumbsStyles';

const BreadcrumbsContext = createContext<IBreadcrumbsContext>({
  breadcrumbs: [],
  setBreadcrumbs: () => null,
});

interface IBreadcrumbsProviderProps {
  children: ReactNode;
}

export function BreadcrumbsProvider({ children }: IBreadcrumbsProviderProps) {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>([]);

  const handleSetBreadcrumbs = (newBreadcrumbs: IBreadcrumbItem[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const value = useMemo(() => {
    return {
      breadcrumbs,
      setBreadcrumbs: handleSetBreadcrumbs,
    };
  }, [breadcrumbs]);

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

const useBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};

export const useSetBreadcrumbs = (breadcrumbs: IBreadcrumbItem[]) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [setBreadcrumbs, breadcrumbs]);

  return { setBreadcrumbs };
};

export function Breadcrumbs() {
  const { classes, cx } = useBreadcrumbsStyles();

  const isSMDown = useIsSMDown();

  const { breadcrumbs } = useContext(BreadcrumbsContext);

  return (
    <MuiBreadcrumbs
      maxItems={isSMDown ? 2 : 8}
      aria-label="breadcrumb"
      separator={<ArrowRight className={classes.separator} />}
      classes={{
        root: classes.breadcrumbsRoot,
        ol: classes.breadcrumbs,
        li: classes.breadcrumbsLi,
        separator: classes.muiSeparator,
      }}
    >
      {breadcrumbs.map((item, index) => {
        const { title, link, onClick } = item;

        const itemClass = cx(
          classes.item,
          breadcrumbs.length > 1 &&
            index === breadcrumbs.length - 1 &&
            classes.itemLast,
        );

        return (
          <Typography
            component={link ? Link : Typography}
            color="inherit"
            to={link || ''}
            onClick={onClick}
            className={itemClass}
            key={`breadcrumb_${item.title}${item.link ?? ''}`}
          >
            {title}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
}
