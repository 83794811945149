import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum RollupDeployStepsEnum {
  None,
  General,
  Sequencer,
  Configuration,
  InfraPackage,
}

export interface IStepData {
  step: RollupDeployStepsEnum;
  passedStep: RollupDeployStepsEnum;
}

const initialState: IStepData = {
  step: RollupDeployStepsEnum.General,
  passedStep: RollupDeployStepsEnum.None,
};

export const rollupDeployStepsSlice = createSlice({
  name: 'rollupDeploySteps',
  initialState,
  reducers: {
    updateStepsData: (state, action: PayloadAction<IStepData>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetStepsData: () => initialState,
  },
});

export const { updateStepsData, resetStepsData } =
  rollupDeployStepsSlice.actions;
