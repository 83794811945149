import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '@ankr.com/raas-themes';
import { makeStyles } from 'tss-react/mui';

export const useSidebarStyles = makeStyles()(theme => ({
  root: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    bottom: 0,
    left: 0,
    minWidth: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
