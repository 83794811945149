import React, { JSX } from 'react';
import { AppModal } from '@ankr.com/raas-ui';
import { Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../common/actions/openDialog';
import { HUBSPOT_URL } from '../../../common/const/values';
import { useDialog } from '../../../common/hooks/useDialog';
import { useTranslation } from '../../../i18n';
import { planCancellationModalTranslation } from './translation';
import { usePlanCancellationModalStyles } from './usePlanCancellationModalStyles';

export function PlanCancellationModal(): JSX.Element {
  const { classes } = usePlanCancellationModalStyles();

  const { keys, t } = useTranslation(planCancellationModalTranslation);

  const {
    open: planCancellationModalOpen,
    handleClose: handlePlanCancellationModalClose,
  } = useDialog(DialogId.PlanCancellation);

  return (
    <AppModal
      open={planCancellationModalOpen}
      onClose={handlePlanCancellationModalClose}
      title={t(keys.title)}
      maxWidth="xs"
      titleCentered
      withoutCloseButton
      titleClassName={classes.modalTitle}
      classes={{ paper: classes.modalContent }}
    >
      <Typography variant="body2" className={classes.modalHint}>
        {t(keys.hint)}
      </Typography>
      <Box className={classes.modalButtons}>
        <Button
          size="large"
          href={HUBSPOT_URL}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.contact)}
        </Button>
        <Button
          onClick={handlePlanCancellationModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
