import React, { JSX } from 'react';
import { Button } from '@mui/material';
import { ButtonPropsSizeOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';

import { useAuth } from '../../../auth/hooks/useAuth';
import { useTranslation } from '../../../i18n';
import { authButtonTranslation } from './translation';

interface IAuthButtonProps {
  className?: string;
  buttonSize?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
}

export function AuthButton({
  className,
  buttonSize,
}: IAuthButtonProps): JSX.Element {
  const { keys, t } = useTranslation(authButtonTranslation);

  const { isSignedIn, handleSignOut, handleSignIn } = useAuth();

  if (isSignedIn) {
    return (
      <Button onClick={handleSignOut} className={className} size={buttonSize}>
        {t(keys.signOut)}
      </Button>
    );
  }

  return (
    <Button onClick={handleSignIn} className={className} size={buttonSize}>
      {t(keys.signIn)}
    </Button>
  );
}
