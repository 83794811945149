import React, { JSX } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';

import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useTranslation } from '../../../i18n';
import { RollupRoutesConfig } from '../../../Rollup/RollupRoutes';
import { featuresConfig } from '../../const/features';
import { listEmptyTranslation } from './translation';
import { useListEmptyStyles } from './useListEmptyStyles';

interface IListEmptyProps {
  title?: string;
}

export function ListEmpty({ title }: IListEmptyProps): JSX.Element {
  const { classes } = useListEmptyStyles();

  const { keys, t } = useTranslation(listEmptyTranslation);

  return (
    <Card className={classes.card}>
      <Typography variant="h5" component="div" className={classes.text}>
        {title ?? t(keys.title)}
      </Typography>
      <Typography variant="body2" component="div" className={classes.text}>
        {t(keys.subtitle)}
      </Typography>
      <Box className={classes.buttonsWrap}>
        <Button
          component={Link}
          to={RollupRoutesConfig.Deploy.generatePath()}
          size="large"
          className={classes.button}
        >
          {t(keys.deployRollup)}
        </Button>
        {featuresConfig.showLinksToNonExistPages && (
          <Button
            component={Link}
            // TODO: path to deploy sidechain
            to={DashboardRoutesConfig.Dashboard.generatePath()}
            size="large"
            className={classes.button}
          >
            {t(keys.deploySidechain)}
          </Button>
        )}
      </Box>
    </Card>
  );
}
