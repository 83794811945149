import React, { JSX } from 'react';
import { Box } from '@mui/material';

import { MainMenu } from '../MainMenu';
import { useSidebarStyles } from './useSidebarStyles';

export function Sidebar(): JSX.Element {
  const { classes } = useSidebarStyles();

  return (
    <Box component="aside" className={classes.root}>
      <MainMenu />
    </Box>
  );
}
