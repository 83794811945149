import {
  HEADER_HEIGHT,
  MIN_LAYOUT_WIDTH,
  SIDEBAR_WIDTH,
} from '@ankr.com/raas-themes';
import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  appBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    height: HEADER_HEIGHT,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },

  mobileMenuIsOpen: {},
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '100%',
    '&&': {
      maxWidth: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
      },
    },
  },
  toolbar: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(8),
    padding: theme.spacing(3, 4.5, 3, 7.5),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(3, 0, 3, 3),
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(3),
      justifyContent: 'flex-end',
    },
  },

  toolbarContent: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },

  logo: {
    color: theme.palette.primary.main,
  },

  logoWrap: {
    minWidth: SIDEBAR_WIDTH,
    height: '100%',
    padding: theme.spacing(0, 4),
    display: 'flex',
    gap: theme.spacing(5),
    fontSize: 20,
    letterSpacing: '-0.04em',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  mobileLogoWrap: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'flex-end',
  },

  logoLink: {
    display: 'block',
    width: '100%',
    paddingLeft: theme.spacing(1),
  },

  mobileMenu: {},
  drawer: {
    borderRadius: 0,
  },
  drawerPaper: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    minWidth: MIN_LAYOUT_WIDTH,
  },

  drawerBackdrop: {},
  mobileMenuWrap: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(5, 4),
  },
  mobileMenuContent: {
    flexGrow: 1,
    padding: theme.spacing(6, 0, 0),
  },

  headerBreadcrumbs: {
    padding: theme.spacing(6, 0),
  },
}));
