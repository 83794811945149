import { makeStyles } from 'tss-react/mui';

export const useAuthGuardStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(25, 10, 29),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(9, 4, 15),
    },
  },
  image: {
    width: 120,
    height: 120,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(7),
  },
  title: {
    marginBottom: theme.spacing(7),
    maxWidth: 700,
  },
  buttonsWrap: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      flexDirection: 'column',
    },
  },
  button: {
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      minWidth: 180,
    },
  },
}));
