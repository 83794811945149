import { makeStyles } from 'tss-react/mui';

export const useBillingHistoryStyles = makeStyles()(theme => ({
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  card: {
    padding: theme.spacing(8),
    '&&': {
      borderRadius: 16,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },

  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  value: {
    fontSize: 14,
  },

  dateColumn: {
    flexGrow: 1,
  },
  typeColumn: {
    flexGrow: 1,
  },
  amountColumn: {
    flexGrow: 1,
  },
}));
