import { getMainThemeOptions } from '@ankr.com/raas-themes';
import { createTheme, PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'dark',

  primary: {
    main: '#339AF0',
    dark: '#1864AB',
    light: '#252F3A',
    contrastText: '#ffffff',
  },

  secondary: {
    main: '#232326',
    dark: '#706F78',
    light: '#EDEDEF',
    contrastText: '#FFFFFF',
  },

  info: {
    main: '#339AF0',
    dark: '#1864AB',
    light: '#252F3A',
    contrastText: '#FFFFFF',
  },

  error: {
    main: '#FF6B6B',
    dark: '#C92A2A',
    light: '#2A1B1B',
    contrastText: '#FFFFFF',
  },

  warning: {
    main: '#FCC419',
    dark: '#E67700',
    light: '#FFEC99',
    contrastText: '#232326',
  },

  success: {
    main: '#51CF66',
    dark: '#2B8A3E',
    light: '#28342C',
    contrastText: '#FFFFFF',
  },

  highlight: {
    main: '#F4E9D0',
    dark: '#FFA030',
  },

  background: {
    default: '#121212',
    paper: '#232326',
  },

  grey: {
    100: '#2C2C30',
    200: '#121212',
    300: '#232326',
    400: '#34343A',
    500: '#504F57',
    600: '#706F78',
    700: '#7E7D86',
    800: '#A09FA6',
    900: '#C4C3C6',
  },

  yellow: {
    main: '#FCC419',
    dark: '#E67700',
    light: '#FFEC99',
  },

  teal: {
    main: '#20C997',
    dark: '#087F5B',
    light: '#96F2D7',
  },

  indigo: {
    main: '#5C7CFA',
    dark: '#364FC7',
    light: '#BAC8FF',
  },

  purple: {
    main: '#CC5DE8',
    dark: '#862E9C',
    light: '#EEBEFA',
  },

  link: {
    main: '#339AF0',
  },

  text: {
    primary: '#FFFFFF',
    secondary: '#7E7D86',
    disabled: '#504F57',
  },

  divider: '#504F57 ',

  common: {
    black: '#ffffff',
    white: '#000000 ',
  },
};

export const fraxTheme = createTheme(getMainThemeOptions({ palette }));
