import React, { JSX } from 'react';
import { tCommon } from '@ankr.com/raas-utils';

import { DECLARED_CHAIN } from '../../Rollup/RollupConst';
import { HOMEPAGE } from '../const/values';

interface IGetChainProps {
  value?: string;
  imageClassName?: string;
}

export function getChain({ value, imageClassName }: IGetChainProps): {
  name: string;
  imageSrc?: string;
  image: JSX.Element | null;
} {
  if (!value) {
    return {
      name: '',
      imageSrc: undefined,
      image: null,
    };
  }

  // TODO: need to change on the backend for l1_chain eth_holesky -> holesky and eth -> ethereum
  let normalizeValue = value === 'eth_holesky' ? 'holesky' : value;
  normalizeValue = value === 'eth' ? 'ethereum' : normalizeValue;

  const valueLower = normalizeValue.toLowerCase();
  const isDeclared = Object.hasOwn(DECLARED_CHAIN, valueLower);

  const imageSrc = isDeclared
    ? `${HOMEPAGE}static/blockchain/${valueLower}.svg`
    : `${HOMEPAGE}static/blockchain/undefined.svg`;

  return {
    name: isDeclared ? tCommon(`blockchain.${valueLower}`) : value,
    imageSrc,
    image: isDeclared ? (
      <img src={imageSrc} alt="" className={imageClassName} />
    ) : null,
  };
}
