import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollTrigger } from '@mui/material';

const SLIDE_TIMEOUT = 500;
const HEADER_TRIGGER_THRESHOLD = 150;

export const useHeader = () => {
  const trigger = useScrollTrigger({ threshold: HEADER_TRIGGER_THRESHOLD });
  const triggerTouched = useScrollTrigger({
    threshold: 10,
    disableHysteresis: true,
  });

  const [mobileNavShowed, setMobileNavShowed] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setMobileNavShowed(false);
  }, [setMobileNavShowed, location]);

  const toggleNav = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (isOpen: boolean) => (event: any) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setMobileNavShowed(isOpen);
    },
    [],
  );

  return {
    trigger,
    SLIDE_TIMEOUT,
    triggerTouched,
    mobileNavShowed,
    toggleNav,
  };
};
