import { Locale } from '../../../../../i18n';

export const RollupBridgeModalTranslation = {
  [Locale.en]: {
    title: 'Bridge Information',
    close: 'Close',
    bridge: 'Bridge',
    openBridge: 'Open Bridge',
    docs: 'Docs',
    openDocs: 'Open Docs',
    contracts: 'Contracts',
    copyContracts: 'Copy Contracts',
    copied: 'Copied!',
  },
};
