import React, { JSX, useMemo } from 'react';
import { tCommon } from '@ankr.com/raas-utils';
import { Container } from '@mui/material';

import { useLocaleMemo } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';
import { DeployRollupFormConfiguration } from './components/DeployRollupFormConfiguration';
import { DeployRollupFormGeneral } from './components/DeployRollupFormGeneral';
import { DeployRollupFormInfraPackage } from './components/DeployRollupFormInfraPackage';
import { DeployRollupFormSequencer } from './components/DeployRollupFormSequencer';
import { DeployRollupPlansModal } from './components/DeployRollupPlansModal';
import { useRollupDeployStepsState } from './hooks/useRollupDeployStepsState';
import { RollupDeployStepsEnum } from './store/rollupDeployStepsSlice';
import { useDeployRollupStyles } from './useDeployRollupStyles';

export function DeployRollup(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const breadcrumbs = useLocaleMemo(
    () => [
      {
        title: tCommon('navigation.rollups'),
      },
      {
        title: tCommon('navigation.deploy-rollup'),
      },
    ],
    [],
  );
  useSetBreadcrumbs(breadcrumbs);

  const rollupDeployStepsState = useRollupDeployStepsState();

  const renderForm = useMemo(() => {
    if (rollupDeployStepsState.step === RollupDeployStepsEnum.Sequencer) {
      return <DeployRollupFormSequencer />;
    }

    if (rollupDeployStepsState.step === RollupDeployStepsEnum.Configuration) {
      return <DeployRollupFormConfiguration />;
    }

    if (rollupDeployStepsState.step === RollupDeployStepsEnum.InfraPackage) {
      return <DeployRollupFormInfraPackage />;
    }

    return <DeployRollupFormGeneral />;
  }, [rollupDeployStepsState.step]);

  return (
    <Container className={classes.root}>
      {renderForm}

      <DeployRollupPlansModal />
    </Container>
  );
}
