import React, { JSX } from 'react';
import { Control, Controller } from 'react-hook-form';
import { LabelWrap, QueryLoadingCentered } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Chip } from '@ankr.com/ui';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';

import { StackDALIcon } from '../../../../../common/components/StackDALIcon';
import {
  IDALItem,
  IDeployRollupFormGeneralPayload,
  IStackItem,
} from '../../../../RollupConst';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

interface IDeployRollupFormStackList {
  control: Control<IDeployRollupFormGeneralPayload>;
  controllerName: keyof Omit<IDeployRollupFormGeneralPayload, 'grade'>;
  title: string;
  subtitle?: string;
  isDALList?: boolean;
  list?: IStackItem[] | IDALItem[];
  isLoading?: boolean;
}

export function DeployRollupFormStackList({
  control,
  controllerName,
  title,
  subtitle,
  isDALList,
  list,
  isLoading,
}: IDeployRollupFormStackList): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  return (
    <Box className={classes.section}>
      <Box className={classes.sectionTitleWrap}>
        <Typography
          className={classes.sectionTitle}
          variant="subtitle1"
          component="div"
        >
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            className={classes.sectionSubtitle}
            variant="body3"
            component="div"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      {isLoading ? (
        <QueryLoadingCentered />
      ) : (
        <Grid container spacing={2.5}>
          <Controller
            name={controllerName}
            control={control}
            rules={{
              required: tCommon('validation.required-one'),
            }}
            render={({ field, fieldState }) => (
              <>
                {list?.map(item => (
                  <Grid
                    item
                    key={`${controllerName}_${item.uuid}`}
                    xs={12}
                    sm={6}
                    lg={list.length === 4 ? 3 : 4}
                  >
                    <LabelWrap
                      className={classes.labelWrap}
                      active={field.value === item.uuid}
                      component={item.comingSoon ? 'div' : 'label'}
                    >
                      <>
                        {item.comingSoon || (
                          <input
                            type="radio"
                            {...field}
                            checked={field.value === item.uuid}
                            value={item.uuid}
                            hidden
                          />
                        )}
                        <Box
                          mb={7.5}
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          width="100%"
                        >
                          <StackDALIcon
                            keyString={item.key}
                            title={item.title}
                            className={classes.itemLogo}
                            isDAL={isDALList}
                          />

                          {item.comingSoon && (
                            <Chip
                              label={tCommon('common.coming-soon')}
                              size="small"
                              color="secondary"
                            />
                          )}
                        </Box>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          mb={3}
                          className={cx(
                            item.comingSoon && classes.textDisabled,
                          )}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body3"
                          component="div"
                          className={cx(
                            item.comingSoon && classes.textDisabled,
                          )}
                        >
                          {item.description}
                        </Typography>
                      </>
                    </LabelWrap>
                  </Grid>
                ))}
                {!!fieldState.error?.message && (
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  </Grid>
                )}
              </>
            )}
          />
        </Grid>
      )}
    </Box>
  );
}
