import React, { JSX } from 'react';

import { HOMEPAGE } from '../../const/values';

interface IStackDALIconProps {
  keyString: string;
  title?: string;
  className?: string;
  isDAL?: boolean;
}

export function StackDALIcon({
  keyString,
  title = '',
  className,
  isDAL,
}: IStackDALIconProps): JSX.Element {
  return (
    <img
      src={`${HOMEPAGE}static/${isDAL ? 'dal' : 'stack'}/${keyString}.svg`}
      alt={title}
      className={className}
    />
  );
}
