import { makeStyles } from 'tss-react/mui';

export const useRollupBridgeModalStyles = makeStyles()(theme => ({
  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 700,
  },

  contractsBox: {
    whiteSpace: 'pre',
    fontFamily: 'monospace',
    fontSize: 12,
    overflow: 'auto',
    fontWeight: 400,
    lineHeight: 1.4,
    color: theme.palette.text.primary,
    borderRadius: 16,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 0 0 2px ${theme.palette.grey[100]}`,
  },

  smallFlatBtn: {
    '&&': {
      border: 0,
      backgroundColor: 'transparent',
    },
  },

  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
