import React, { JSX } from 'react';
import { Box, Card, Container, Typography } from '@mui/material';

import { useAuth } from '../../auth/hooks/useAuth';
import { AuthButton } from '../../common/components/AuthButton';
import { HOMEPAGE, PROJECT_TITLE, PUBLIC_DIR } from '../../common/const/values';
import { useTranslation } from '../../i18n';
import { authGuardTranslation } from './translation';
import { useAuthGuardStyles } from './useAuthGuardStyles';

interface AuthGuardProps {
  children: JSX.Element;
}

export function AuthGuard({ children }: AuthGuardProps): JSX.Element {
  const { classes } = useAuthGuardStyles();

  const { keys, t } = useTranslation(authGuardTranslation);

  const { isSignedIn } = useAuth();

  return isSignedIn ? (
    children
  ) : (
    <Container>
      <Card className={classes.root}>
        <img
          className={classes.image}
          src={`${HOMEPAGE}${PUBLIC_DIR}/logo.svg`}
          alt={PROJECT_TITLE}
        />
        <Typography variant="h6" component={Box} className={classes.title}>
          {t(
            keys.title,
            {
              project: PROJECT_TITLE,
            },
            true,
          )}
        </Typography>

        <Box className={classes.buttonsWrap}>
          <AuthButton className={classes.button} buttonSize="large" />
        </Box>
      </Card>
    </Container>
  );
}
