import React, { JSX, useMemo } from 'react';
import {
  AppList,
  IAdditionalItemProps,
  IAppListColumn,
} from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { ExternalLink } from '@ankr.com/ui';
import { Box, Button, Container, Typography } from '@mui/material';

import { ExpiredDateChip } from '../../../common/components/ExpiredDateChip';
import { GradeChip } from '../../../common/components/GradeChip';
import { ListEmpty } from '../../../common/components/ListEmpty';
import { RollupStatusChip } from '../../../common/components/RollupStatusChip';
import { StackDALIcon } from '../../../common/components/StackDALIcon';
import {
  ROLLUP_DEPLOYMENT_DOCS_URL,
  ROLLUP_POLLING_INTERVAL,
} from '../../../common/const/values';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';
import { IRollupDeployed, ROLLUP_STATUS } from '../../../Rollup/RollupConst';
import { RollupRoutesConfig } from '../../../Rollup/RollupRoutes';
import { useGetRollupDeploysQuery } from '../../api/getRollupDeploys';
import { dashboardTranslation } from '../../translation';
import { useDashboardStyles } from './useDashboardStyles';

interface IRollupDeployedPrepared
  extends IRollupDeployed,
    IAdditionalItemProps {}

export function Dashboard(): JSX.Element {
  const { classes } = useDashboardStyles();

  const { keys, t } = useTranslation(dashboardTranslation);

  const breadcrumbs = useLocaleMemo(
    () => [
      {
        title: tCommon('navigation.dashboard'),
      },
    ],
    [],
  );
  useSetBreadcrumbs(breadcrumbs);

  const {
    data: rollupListData,
    isLoading: isRollupListLoading,
    isSuccess: isRollupListSuccess,
    isError: isRollupListError,
  } = useGetRollupDeploysQuery(undefined, {
    pollingInterval: ROLLUP_POLLING_INTERVAL,
    skipPollingIfUnfocused: true,
    selectFromResult: result => {
      if (result.data) {
        return {
          ...result,
          data: result.data.map(item => ({
            ...item,
            rowLink: RollupRoutesConfig.Rollup.generatePath({
              uuid: item.uuid,
            }),
          })),
        };
      }

      return result;
    },
  });

  const isRollupListEmpty = useMemo(
    () =>
      (isRollupListSuccess || isRollupListError) &&
      (!rollupListData || !rollupListData?.length),
    [isRollupListError, isRollupListSuccess, rollupListData],
  );

  const columns: IAppListColumn<IRollupDeployed>[] = useLocaleMemo(
    () => [
      {
        id: 'idColumn',
        className: classes.idColumn,
        header: (
          <Box className={classes.headerContent}>
            {t(keys.rollupList.network)}
          </Box>
        ),
        renderCell: item => (
          <Box display="flex" gap={2.5} alignItems="center">
            {item.plan.stack.key && item.plan.stack.title && (
              <StackDALIcon
                keyString={item.plan.stack.key}
                title={item.plan.stack.title}
                className={classes.stackLogo}
              />
            )}
            <Box textAlign="left">
              <Typography variant="body2" component={Box}>
                {item.networkName}
              </Typography>
              <GradeChip grade={item.plan.grade} />
            </Box>
          </Box>
        ),
      },
      {
        id: 'statusColumn',
        className: classes.statusColumn,
        header: (
          <Box className={classes.headerContent}>
            {t(keys.rollupList.status)}
          </Box>
        ),
        renderCell: item => <RollupStatusChip status={item.status} />,
      },
      {
        id: 'DALColumn',
        className: classes.DALColumn,
        header: (
          <Box className={classes.headerContent}>{t(keys.rollupList.DAL)}</Box>
        ),
        renderCell: item => (
          <Typography variant="body2" component={Box} className={classes.value}>
            {item.dataAvailabilityLayer ? (
              <Box display="flex" gap={2.5} alignItems="center">
                <StackDALIcon
                  keyString={item.dataAvailabilityLayer.key}
                  title={item.dataAvailabilityLayer.title}
                  className={classes.blockchainLogo}
                  isDAL
                />
                {item.dataAvailabilityLayer.title}
              </Box>
            ) : (
              tCommon('common.empty-placeholder')
            )}
          </Typography>
        ),
      },
      {
        id: 'dateColumn',
        className: classes.dateColumn,
        header: (
          <Box className={classes.headerContent}>
            {t(keys.rollupList.createdDate)}
          </Box>
        ),
        renderCell: item => (
          <Typography variant="body2" component={Box} className={classes.value}>
            {item.createdAt
              ? tCommon('datetime.medium-short', { value: item.createdAt })
              : tCommon('common.empty-placeholder')}
          </Typography>
        ),
      },
      {
        id: 'addColumn',
        className: classes.addColumn,
        header: null,
        renderCell: item => {
          return (
            <Box className={classes.value} display="flex" gap={2}>
              {!!item.paidUntil &&
                item.status !== ROLLUP_STATUS.pending &&
                item.status !== ROLLUP_STATUS.pending_deposit &&
                item.status !== ROLLUP_STATUS.unpaid &&
                item.status !== ROLLUP_STATUS.failed &&
                item.status !== ROLLUP_STATUS.expired && (
                  <ExpiredDateChip date={item.paidUntil} size="medium" />
                )}
            </Box>
          );
        },
      },
    ],
    [
      classes.DALColumn,
      classes.addColumn,
      classes.blockchainLogo,
      classes.dateColumn,
      classes.headerContent,
      classes.idColumn,
      classes.stackLogo,
      classes.statusColumn,
      classes.value,
      keys.rollupList.DAL,
      keys.rollupList.createdDate,
      keys.rollupList.network,
      keys.rollupList.status,
      t,
    ],
  );

  return (
    <Container>
      <Box className={classes.titleWrap}>
        <Typography variant="h6">{t(keys.myRollups.pageTitle)}</Typography>
        <Button
          variant="text"
          size="small"
          color="primary"
          endIcon={<ExternalLink />}
          href={ROLLUP_DEPLOYMENT_DOCS_URL}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.myRollups.getStarted)}
        </Button>
      </Box>
      {isRollupListEmpty ? (
        <ListEmpty />
      ) : (
        <AppList
          items={(rollupListData as IRollupDeployedPrepared[]) ?? []}
          isLoading={isRollupListLoading}
          columns={columns}
          getKey={item => item.uuid}
        />
      )}
    </Container>
  );
}
