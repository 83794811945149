import { useAuth as useAuthHook } from '@ankr.com/auth';

import { AUTH_APP_ID, AUTH_APP_PATH, AUTH_SERVICE_PATH } from '../const';

type TUseAuth = ReturnType<typeof useAuthHook>;

const authParams = {
  authPath: AUTH_SERVICE_PATH,
  appPath: AUTH_APP_PATH || window.location.host,
  appId: AUTH_APP_ID,
};

export function useAuth(): TUseAuth {
  return useAuthHook(authParams);
}
