import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { Gateways, mainApi } from '../../../../api';
import { IStackItem } from '../../../RollupConst';
import { mapStacksFromApi } from '../utils/mapStacksFromApi';

export const { useGetStacksQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getStacks: build.query<IStackItem[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IStackItem[]>(async () => {
        const { data } = await Gateways.noAuth.api.get('/api/v1/stacks', {
          method: 'GET',
        });

        return {
          data: mapStacksFromApi(data),
        };
      }),
    }),
  }),
});
