import { useCallback, useId, useLayoutEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';

import { Locale } from '../types';
import { transformKey } from '../utils/transformKey';

const DEFAULT_LOCALE = Locale.en;

type TFunction = (
  key: string,
  variables?: unknown,
  withHTML?: boolean,
) => string;

// todo: need to think about how to make en required and the rest optional
type PartialLocale = Locale | string;

type Translation<T extends Record<string, string | Record<string, string>>> =
  Record<PartialLocale, T>;

type UseTranslationResult<
  T extends Record<string, string | Record<string, string>>,
> = {
  t: TFunction;
  keys: T;
  locale: Locale;
};

export function useTranslation<
  T extends Record<string, string | Record<string, string>>,
>(data: Translation<T>): UseTranslationResult<T> {
  const id = useId();

  const [isLoaded, setIsLoaded] = useState(false);

  const keys = useMemo(() => {
    return Object.entries(data[DEFAULT_LOCALE]).reduce((obj, [key, value]) => {
      return {
        ...obj,
        [key]: transformKey(id, key, value),
      };
    }, {} as T);
  }, [data, id]);

  useLayoutEffect(() => {
    if (isLoaded && intl.get(id)) {
      return;
    }

    const intlData = Object.entries(data).reduce(
      (localeData, [locale, text]) => {
        return {
          ...localeData,
          [locale]: {
            [id]: text,
          },
        };
      },
      {},
    );

    intl.load(intlData);
    setIsLoaded(true);
  }, [data, id, isLoaded]);

  const t: TFunction = useCallback(
    (key, variables, withHTML) => {
      if (!isLoaded) {
        return key;
      }

      if (withHTML) {
        return intl.getHTML(key, variables) || key;
      }

      return intl.get(key, variables) || key;
    },
    [isLoaded],
  );

  return {
    t,
    keys,
    locale: DEFAULT_LOCALE,
  };
}
