import React, { JSX } from 'react';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { Close } from '@ankr.com/ui';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import imgUrl from '../../../../../common/assets/success.png';
import imgUrl2x from '../../../../../common/assets/success@2x.png';
import { ROLLUP_TESTNET_DURATION } from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { rollupTranslation } from '../../translation';
import { useRollupStyles } from '../../useRollupStyles';

export function RollupExtendedModal(): JSX.Element {
  const { classes } = useRollupStyles();

  const { keys, t } = useTranslation(rollupTranslation);

  const {
    open: extendedRollupModalOpen,
    handleClose: handleExtendedRollupModalClose,
  } = useDialog(DialogId.ExtendedRollupTestnet);

  return (
    <AppModal
      open={extendedRollupModalOpen}
      onClose={handleExtendedRollupModalClose}
      maxWidth="xs"
      withoutCloseButton
      classes={{ paper: classes.successModal }}
    >
      <Box className={classes.successModalContent}>
        <IconButton
          aria-label="close"
          onClick={handleExtendedRollupModalClose}
          color="inherit"
          className={classes.successModalCloseBtn}
        >
          <Close sx={{ color: 'inherit' }} />
        </IconButton>

        <ImageX
          imgUrl={imgUrl}
          img2xUrl={imgUrl2x}
          className={classes.successModalImage}
        />

        <Typography variant="h5">{t(keys.extendRollupModal.title)}</Typography>
        <Typography variant="body3" className={classes.successModalHint}>
          {t(keys.extendRollupModal.hint, {
            duration: ROLLUP_TESTNET_DURATION,
          })}
        </Typography>

        <Button
          onClick={handleExtendedRollupModalClose}
          size="large"
          fullWidth
          className={classes.successModalBtn}
        >
          {t(keys.extendRollupModal.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
