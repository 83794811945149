import { makeStyles } from 'tss-react/mui';

export const useRollupDeleteModalStyles = makeStyles()(theme => ({
  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 570,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  modalTitle: {
    fontSize: 35,
  },
  modalHint: {
    color: theme.palette.text.secondary,
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(10),
  },
}));
