import { Locale } from '../../../../../i18n';

export const rollupDeleteModalTranslation = {
  [Locale.en]: {
    title: 'Are you sure?',
    hint: "Redeployment or recovery of your rollup after deletion won't be possible. If you are sure, please contact our support team below with your request to delete.",
    contactSupport: 'Contact Support',
    close: 'Close',
  },
};
