import { IDALItem, IDALItemApi } from '../../../RollupConst';

export function mapDALFromApi(DAL: IDALItemApi): IDALItem {
  // TODO: remove it when the backend will provide keys for all DALs
  const keyFromIcon = DAL.icon?.split('/').at(-1)?.split('.').at(0);

  return {
    description: DAL.description,
    key: DAL.key || keyFromIcon || '',
    title: DAL.title,
    uuid: DAL.uuid,
    comingSoon: DAL.coming_soon,
    gasTokens: DAL.gas_tokens,
  };
}

export function mapDALsFromApi(DALs: IDALItemApi[]): IDALItem[] {
  return DALs?.map((DAL: IDALItemApi) => mapDALFromApi(DAL));
}
