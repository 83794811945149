import React, { JSX } from 'react';
import { ExternalLink } from '@ankr.com/ui';
import { Box, Button, Typography } from '@mui/material';

import { ROLLUP_DEPLOYMENT_DOCS_URL } from '../../../../../common/const/values';
import { useTranslation } from '../../../../../i18n';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupStepper } from '../DeployRollupStepper';

export function DeployRollupFormHeader(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  return (
    <Box className={classes.formHeader}>
      <Box className={classes.titleWrap}>
        <Typography variant="h5">{t(keys.deployRollup.pageTitle)}</Typography>
        <Button
          variant="text"
          size="small"
          color="primary"
          endIcon={<ExternalLink />}
          href={ROLLUP_DEPLOYMENT_DOCS_URL}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.deployRollup.getStarted)}
        </Button>
      </Box>

      <DeployRollupStepper />
    </Box>
  );
}
