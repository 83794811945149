import { Locale } from '../../../i18n';

export const listEmptyTranslation = {
  [Locale.en]: {
    title: 'No projects added yet',
    subtitle: 'Deploy your first project using the button below',
    deployRollup: 'Deploy Rollup',
    deploySidechain: 'Deploy Sidechain',
  },
};
