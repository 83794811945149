import { Locale } from '../../../../../i18n';

export const accountDeleteModalTranslation = {
  [Locale.en]: {
    title: 'Are you sure?',
    hint: "Recovering your account after deletion won't be possible. If you are sure, please contact our support team with your request to delete.",
    contact: 'Contact Support',
    close: 'Close',
  },
};
