import React, { JSX, useMemo } from 'react';
import { useIsInPast } from '@ankr.com/raas-hooks';
import { Days } from '@ankr.com/raas-types';
import { addDays, tCommon } from '@ankr.com/raas-utils';
import { Chip } from '@ankr.com/ui';

import { useLocaleMemo, useTranslation } from '../../../i18n';
import { CountDownString } from '../CountDownString';
import { expiredTranslation } from './translation';

interface IExpiredDateChipProps {
  date: Date;
  size?: 'small' | 'medium';
  forceColor?: 'primary' | 'secondary' | 'error';
  forceString?: string;
}

const CUTOFF_EXPIRED_DAYS: Days = -1;

export function ExpiredDateChip({
  date,
  size = 'small',
  forceColor,
  forceString,
}: IExpiredDateChipProps): JSX.Element | null {
  const { keys, t } = useTranslation(expiredTranslation);

  const isInPast = useIsInPast({ endDate: date });

  const isCutoffInPast = useIsInPast({
    endDate: addDays({ date, days: CUTOFF_EXPIRED_DAYS }),
  });

  const color = useMemo(() => {
    if (forceColor) {
      return forceColor;
    }
    if (isInPast || isCutoffInPast) {
      return 'error';
    }
    return 'secondary';
  }, [forceColor, isCutoffInPast, isInPast]);

  const label = useLocaleMemo(() => {
    if (isInPast) {
      return t(keys.expired.expired);
    }
    if (isCutoffInPast) {
      if (forceString) {
        return (
          <>
            {t(forceString)}
            <CountDownString date={date} />
          </>
        );
      }
      return <CountDownString date={date} />;
    }
    return (
      <>
        {t(forceString ?? keys.expired.until)}
        {tCommon('datetime.date-medium', { value: date })}
      </>
    );
  }, [
    isInPast,
    isCutoffInPast,
    t,
    forceString,
    keys.expired.until,
    keys.expired.expired,
    date,
  ]);

  return (
    <Chip
      label={label}
      color={color}
      size={size}
      sx={{ fontFeatureSettings: '"tnum" 1' }}
    />
  );
}
