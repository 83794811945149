import { useMemo } from 'react';

import { useRollupDeployStepsState } from './useRollupDeployStepsState';

export const useIsRollupDeployStepPassed = () => {
  const stepsState = useRollupDeployStepsState();

  return useMemo(
    () => stepsState.passedStep >= stepsState.step,
    [stepsState.passedStep, stepsState.step],
  );
};
