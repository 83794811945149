import { JSX } from 'react';
import { tCommon } from '@ankr.com/raas-utils';

import { AccountInfoForm } from '../../../common/components/AccountInfoForm';
import { useLocaleMemo } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';

export function Settings(): JSX.Element {
  const breadcrumbs = useLocaleMemo(
    () => [
      {
        title: tCommon('navigation.settings'),
      },
    ],
    [],
  );
  useSetBreadcrumbs(breadcrumbs);

  return <AccountInfoForm />;
}
