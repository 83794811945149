import { makeStyles } from 'tss-react/mui';

export const useRollupPayModalStyles = makeStyles()(theme => ({
  vAlignMiddle: {
    verticalAlign: 'middle',
  },

  alertBlock: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },

  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 600,
  },

  logoImg: {
    width: 20,
    height: 20,
  },

  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
