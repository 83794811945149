import { makeStyles } from 'tss-react/mui';

export const useMainMenuStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    padding: theme.spacing(3),
    color: theme.palette.text.disabled,
  },
  hr: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderColor: theme.palette.divider,
  },
  button: {
    minHeight: 48,
    padding: theme.spacing(3),
    justifyContent: 'flex-start',
    gap: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontWeight: 400,
    borderRadius: 16,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
  },

  poweredByButton: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    justifyContent: 'center',
  },
  poweredByLogo: {
    maxWidth: '100%',
  },
}));
