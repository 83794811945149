import { useCallback, useState } from 'react';
import { Web3Address } from '@ankr.com/raas-types';
import { isIntegerPositive, tCommon } from '@ankr.com/raas-utils';
import { validator } from 'web3';

import { MAX_CHAIN_ID } from '../../../../../../common/const/values';
import {
  IValidateTokenLevels,
  useLazyGetCustomGasTokenAddressValidationQuery,
} from '../../../api/getCustomGasTokenAddressValidation';
import { useLazyGetIsChainIDUsedQuery } from '../../../api/getIsChainIDUsed';
import { useRollupDeployState } from '../../../hooks/useRollupDeployState';

interface IValidateTokenAddressLevels extends IValidateTokenLevels {
  isValidAddress?: boolean;
}

export const useConfigurationStepValidation = () => {
  const rollupDeployState = useRollupDeployState();

  const [isChainIDUsedTrigger, { isFetching: isChainIDUsedFetching }] =
    useLazyGetIsChainIDUsedQuery();

  const validateChainID = useCallback(
    async (chainID: number) => {
      if (!isIntegerPositive(chainID)) {
        return tCommon('validation.require-positive-integer');
      }
      if (chainID) {
        if (chainID > MAX_CHAIN_ID) {
          return tCommon('validation.chain-id-less-than', {
            value: MAX_CHAIN_ID,
          });
        }

        const data = await isChainIDUsedTrigger({
          chainID,
        }).then(({ data }) => {
          return data;
        });
        if (data?.used) {
          return tCommon('validation.chain-id-used');
        }
      }
    },
    [isChainIDUsedTrigger],
  );

  const [validateAddressTrigger, { isFetching: isAddressValidationFetching }] =
    useLazyGetCustomGasTokenAddressValidationQuery();

  const [gasTokenAddressValidation, setGasTokenAddressValidation] =
    useState<IValidateTokenAddressLevels>();

  const validateAddress = useCallback(
    async (address: Web3Address | undefined) => {
      if (!address || (!!address && !validator.isAddress(address))) {
        setGasTokenAddressValidation({
          isValidAddress: false,
        });
        return '';
      }
      const data = await validateAddressTrigger({
        grade: rollupDeployState.grade,
        address,
      }).then(({ data }) => {
        return data;
      });

      setGasTokenAddressValidation({
        isValidAddress: true,
        isErc20: data?.isErc20,
        is18Decimals: data?.is18Decimals,
        isNonRebasing: data?.isNonRebasing,
      });

      if (data?.isInvalid) {
        return '';
      }
    },
    [rollupDeployState.grade, validateAddressTrigger],
  );

  return {
    isChainIDUsedFetching,
    validateChainID,
    validateAddress,
    isAddressValidationFetching,
    gasTokenAddressValidation,
  };
};
