import { useTimer } from 'react-timer-hook';
import { tCommon } from '@ankr.com/raas-utils';

import { useLocaleMemo } from '../../i18n';

interface IUseCountdownProps {
  date: Date;
}

export function useCountdown({ date }: IUseCountdownProps): string {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: date,
  });

  const daysString = useLocaleMemo(() => {
    if (days) {
      return `${tCommon('unit.day-short', { value: days })}${tCommon('common.colon')}`;
    }
    return '';
  }, [days]);

  const hoursString = useLocaleMemo(() => {
    if (!!daysString || hours) {
      return `${tCommon('unit.hour-short', { value: hours.toString().padStart(2, '0') })}${tCommon('common.colon')}`;
    }
    return '';
  }, [daysString, hours]);

  const minutesString = useLocaleMemo(() => {
    if (!!hoursString || minutes) {
      return `${tCommon('unit.minute-short', {
        value: minutes.toString().padStart(2, '0'),
      })}${tCommon('common.colon')}`;
    }
    return '';
  }, [hoursString, minutes]);

  const secondsString = useLocaleMemo(() => {
    return tCommon('unit.second-short', {
      value: seconds.toString().padStart(2, '0'),
    });
  }, [seconds]);

  return `${daysString}${hoursString}${minutesString}${secondsString}`;
}
