import React, { JSX, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LabelWrap } from '@ankr.com/raas-ui';
import { isValidWebsiteUrl, tCommon } from '@ankr.com/raas-utils';
import { Chip, ExternalLink, TextField } from '@ankr.com/ui';
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { validator } from 'web3';

import { featuresConfig } from '../../../../../common/const/features';
import {
  SELF_SEQUENCER_DOCS_URL,
  SELF_SEQUENCER_FUNDS,
} from '../../../../../common/const/values';
import { useDepositsForPlan } from '../../../../../common/hooks/useDepositsForPlan';
import { useTranslation } from '../../../../../i18n';
import {
  IDeployRollupFormSequencerPayload,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../../RollupConst';
import { useGetPlansQuery } from '../../api/getPlans';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';

export function DeployRollupFormSequencer(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const rollupDeployState = useRollupDeployState();

  const { data: plans } = useGetPlansQuery(
    {
      grade: rollupDeployState.grade,
      stack: rollupDeployState.stack,
    },
    {
      skip: !rollupDeployState.grade || !rollupDeployState.stack,
    },
  );

  const currentPlan = useMemo(
    () => plans?.find(plan => plan.uuid === rollupDeployState.planUuid),
    [plans, rollupDeployState.planUuid],
  );

  const depositAmountByGasToken = useDepositsForPlan({
    deposits: currentPlan?.deposits,
    gasTokenType: rollupDeployState.gasToken,
  });

  const { dispatchData } = useDispatchRollupDeployData();

  const { control, handleSubmit, getValues, watch } =
    useForm<IDeployRollupFormSequencerPayload>({
      defaultValues: {
        sequencerManagementType: rollupDeployState.sequencerManagementType,
        sequencerAddress: rollupDeployState.sequencerAddress,
        sequencerUrl: rollupDeployState.sequencerUrl,
      },
    });

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormSequencerPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.sectionTitleWrap}>
            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {t(keys.deployRollupSequencer.title)}
            </Typography>
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {t(keys.deployRollupSequencer.subtitle)}
            </Typography>
          </Box>

          <Grid container spacing={2} mb={5}>
            <Controller
              name="sequencerManagementType"
              control={control}
              rules={{
                required: tCommon('validation.required'),
              }}
              render={({ field, fieldState }) => (
                <>
                  <Grid item xs={12} sm={6}>
                    <LabelWrap
                      className={classes.labelWrap}
                      active={field.value === SEQUENCER_MANAGEMENT_TYPE.product}
                    >
                      <>
                        <input
                          type="radio"
                          {...field}
                          checked={
                            field.value === SEQUENCER_MANAGEMENT_TYPE.product
                          }
                          value={SEQUENCER_MANAGEMENT_TYPE.product}
                          hidden
                        />
                        <Box mb={3} display="flex" gap={2}>
                          <Typography variant="subtitle2">
                            {t(keys.deployRollupSequencer.productTitle)}
                          </Typography>
                        </Box>
                        <Typography variant="body3" component="div">
                          {t(keys.deployRollupSequencer.productHint, {
                            amount: depositAmountByGasToken,
                          })}
                        </Typography>
                      </>
                    </LabelWrap>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelWrap
                      className={classes.labelWrap}
                      active={field.value === SEQUENCER_MANAGEMENT_TYPE.self}
                      component={
                        featuresConfig.selfSequencerManageAvailable
                          ? 'label'
                          : 'div'
                      }
                    >
                      <>
                        {featuresConfig.selfSequencerManageAvailable && (
                          <input
                            type="radio"
                            {...field}
                            checked={
                              field.value === SEQUENCER_MANAGEMENT_TYPE.self
                            }
                            value={SEQUENCER_MANAGEMENT_TYPE.self}
                            hidden
                          />
                        )}
                        <Box
                          mb={3}
                          display="flex"
                          gap={2}
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography
                            variant="subtitle2"
                            className={cx(
                              featuresConfig.selfSequencerManageAvailable ||
                                classes.textDisabled,
                            )}
                          >
                            {t(keys.deployRollupSequencer.selfTitle)}
                          </Typography>
                          {featuresConfig.selfSequencerManageAvailable || (
                            <Chip
                              label={tCommon('common.coming-soon')}
                              size="small"
                              color="secondary"
                            />
                          )}
                        </Box>
                        <Typography
                          variant="body3"
                          component="div"
                          mb={4}
                          className={cx(
                            featuresConfig.selfSequencerManageAvailable ||
                              classes.textDisabled,
                          )}
                        >
                          {t(keys.deployRollupSequencer.selfHint, {
                            amount: SELF_SEQUENCER_FUNDS,
                          })}
                        </Typography>
                        {featuresConfig.selfSequencerManageAvailable &&
                          !!SELF_SEQUENCER_DOCS_URL && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              endIcon={<ExternalLink />}
                              href={SELF_SEQUENCER_DOCS_URL}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {t(keys.deployRollup.viewInstructions)}
                            </Button>
                          )}
                      </>
                    </LabelWrap>
                  </Grid>
                  {!!fieldState.error?.message && (
                    <Grid item xs={12}>
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Grid>
                  )}
                </>
              )}
            />
          </Grid>

          {watch('sequencerManagementType') ===
            SEQUENCER_MANAGEMENT_TYPE.self && (
            <>
              <Box className={classes.sectionHalfWrap}>
                <Controller
                  name="sequencerAddress"
                  rules={{
                    required: tCommon('validation.required'),
                    validate: v =>
                      validator.isAddress(v ?? '') ||
                      tCommon('validation.invalid-address'),
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t(keys.deployRollupSequencer.addressLabel)}
                    />
                  )}
                />
              </Box>
              <Box className={classes.sectionHalfWrap}>
                <Controller
                  name="sequencerUrl"
                  rules={{
                    required: tCommon('validation.required'),
                    validate: {
                      isValidWebsiteUrl: v =>
                        isValidWebsiteUrl(v ?? '') ||
                        tCommon('validation.invalid-url'),
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      type="url"
                      label={t(keys.deployRollupSequencer.urlLabel)}
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Box>
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
