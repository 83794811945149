import React, { JSX, useEffect } from 'react';
import { useIsInPast } from '@ankr.com/raas-hooks';
import { Days } from '@ankr.com/raas-types';
import { addDays, tCommon } from '@ankr.com/raas-utils';
import { Chip, LoadingButton } from '@ankr.com/ui';
import { Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { ExpiredDateChip } from '../../../../../common/components/ExpiredDateChip';
import { GradeChip } from '../../../../../common/components/GradeChip';
import { RollupPayModal } from '../../../../../common/components/RollupPayModal';
import { RollupStatusChip } from '../../../../../common/components/RollupStatusChip';
import { StackDALIcon } from '../../../../../common/components/StackDALIcon';
import { ROLLUP_TESTNET_DURATION } from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { GRADE, IRollupDeployed, ROLLUP_STATUS } from '../../../../RollupConst';
import { useExtendRollupMutation } from '../../api/extendRollup';
import { rollupTranslation } from '../../translation';
import { useRollupStyles } from '../../useRollupStyles';
import { RollupExtendedModal } from '../RollupExtendedModal';
import { RollupSubActionsMenu } from '../RollupSubActionsMenu';

interface IRollupHeaderProps {
  rollupData?: IRollupDeployed;
  isExpired?: boolean;
}

const CUTOFF_EXPIRED_DAYS: Days = -1;

export function RollupHeader({
  rollupData,
  isExpired,
}: IRollupHeaderProps): JSX.Element {
  const { classes } = useRollupStyles();

  const { keys, t } = useTranslation(rollupTranslation);

  const { handleOpen: handleRollupPayOpen } = useDialog(DialogId.RollupPay);

  const isExtendableTestnet = useIsInPast({
    endDate: addDays({
      date: rollupData?.paidUntil,
      days: CUTOFF_EXPIRED_DAYS,
    }),
  });

  const [
    extendRollup,
    { isLoading: isExtendRollupLoading, isSuccess: isExtendRollupSuccess },
  ] = useExtendRollupMutation();

  const { handleOpen: handleExtendedRollupModalOpen } = useDialog(
    DialogId.ExtendedRollupTestnet,
  );

  useEffect(() => {
    if (isExtendRollupSuccess) {
      handleExtendedRollupModalOpen();
    }
  }, [handleExtendedRollupModalOpen, isExtendRollupSuccess]);

  return (
    <>
      <Box className={classes.titleWrap}>
        <Box display="flex" gap={2.5} alignItems="center" flexWrap="wrap">
          {rollupData?.plan.stack.key && rollupData?.plan.stack.title && (
            <StackDALIcon
              keyString={rollupData?.plan.stack.key}
              title={rollupData?.plan.stack.title}
              className={classes.stackLogo}
            />
          )}
          <Typography variant="subtitle1" component={Box}>
            {rollupData?.networkName}
          </Typography>
          <GradeChip
            grade={rollupData?.plan.grade}
            size="medium"
            forceColor="primary"
          />
          {rollupData?.plan.grade === GRADE.mainnet && (
            <Chip
              label={rollupData?.plan.title}
              color="primary"
              size="medium"
            />
          )}
          <RollupStatusChip status={rollupData?.status} />
        </Box>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          {!!rollupData?.paidUntil &&
            rollupData?.status !== ROLLUP_STATUS.pending &&
            rollupData?.status !== ROLLUP_STATUS.pending_deposit &&
            rollupData?.status !== ROLLUP_STATUS.unpaid &&
            rollupData?.status !== ROLLUP_STATUS.failed &&
            rollupData?.status !== ROLLUP_STATUS.expired && (
              <ExpiredDateChip
                date={rollupData.paidUntil}
                size="medium"
                forceString={
                  rollupData?.plan.grade === GRADE.mainnet
                    ? keys.rollup.nextPayment
                    : undefined
                }
              />
            )}

          {rollupData?.plan.grade === GRADE.mainnet &&
            (rollupData?.status === ROLLUP_STATUS.unpaid ||
              rollupData?.status === ROLLUP_STATUS.expired) &&
            rollupData?.extendable && (
              <>
                <Button size="small" onClick={handleRollupPayOpen}>
                  {t(keys.rollup.pay, {
                    price: tCommon('number.price', {
                      value: rollupData?.plan.price,
                    }),
                  })}
                </Button>
                <RollupPayModal rollup={rollupData} />
              </>
            )}

          {rollupData?.plan.grade === GRADE.testnet &&
            rollupData?.extendable &&
            !isExpired &&
            isExtendableTestnet && (
              <LoadingButton
                size="small"
                onClick={() => extendRollup({ uuid: rollupData?.uuid })}
                loading={isExtendRollupLoading}
              >
                {t(keys.rollup.extend, {
                  duration: tCommon('unit.hour-short', {
                    value: ROLLUP_TESTNET_DURATION,
                  }),
                })}
              </LoadingButton>
            )}

          <RollupSubActionsMenu
            grade={rollupData?.plan.grade}
            rollupUuid={rollupData?.uuid}
          />
        </Box>
      </Box>
      {rollupData?.plan.grade === GRADE.testnet && <RollupExtendedModal />}
    </>
  );
}
