import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useRollupStyles = makeStyles()(theme => ({
  root: {},

  contentWrap: {},

  card: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(6, 5),
    '&&': {
      borderRadius: 16,
    },
  },
  cardSmall: {
    padding: theme.spacing(4, 5),
    '&&': {
      borderRadius: 16,
    },
  },
  cardDisabled: {
    backgroundColor: alpha(theme.palette.background.paper, 0.6),
  },

  infoTitle: {
    color: theme.palette.text.secondary,
  },

  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  listBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  listRowLeft: {
    wordBreak: 'break-word',
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },

  packageList: {},
  packageItem: {
    minHeight: 30,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(5),
    borderRight: `2px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(3n+3)': {
        paddingRight: 0,
        borderRight: 0,
      },
    },
    [theme.breakpoints.only('sm')]: {
      '&:nth-of-type(2n+2)': {
        paddingRight: 0,
        borderRight: 0,
      },
    },
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
      borderRight: 0,
    },
  },

  smallFlatBtn: {
    '&&': {
      paddingTop: 0,
      paddingBottom: 0,
      border: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      height: 'auto',
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },

  depositAlert: {
    width: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(1.75),
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.error.light,
  },
  depositAlertMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  depositButton: {},

  stackLogo: {
    width: 32,
    height: 32,
  },
  blockchainLogo: {
    width: 24,
    height: 24,
  },

  successModal: {
    maxWidth: 600,
  },
  successModalContent: {
    position: 'relative',
    padding: theme.spacing(10, 5, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  successModalCloseBtn: {
    position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(5),
  },
  successModalImage: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2),
  },
  successModalHint: {
    color: theme.palette.text.secondary,
  },
  successModalBtn: {
    marginTop: theme.spacing(10),
  },
}));
