/**
 * Path to authentication service
 */
export const AUTH_SERVICE_PATH = import.meta.env.VITE_AUTH_SERVICE_PATH;

/**
 * Path to the application to redirect from the authentication service (domain only)
 */
export const AUTH_APP_PATH = import.meta.env.VITE_AUTH_APP_PATH;

/**
 * Application ID in the authentication service
 */
export const AUTH_APP_ID = import.meta.env.VITE_AUTH_APP_ID;
