import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { cacheTags } from './cacheTags';
import { API_BASE_URL } from './const';

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(cacheTags),
});
