import React, { JSX } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from '@ankr.com/auth';
import { QueryLoadingAbsolute } from '@ankr.com/raas-ui';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Gateways } from './modules/api';
import { HOMEPAGE } from './modules/common/const/values';
import { AppBase } from './modules/layout/components/AppBase';
import { BreadcrumbsProvider } from './modules/layout/components/Breadcrumbs';
import { AppRoutes } from './Routes';
import { persistor, store } from './store';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={<QueryLoadingAbsolute />} persistor={persistor}>
        <BrowserRouter basename={HOMEPAGE}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AuthContextProvider Gateways={Gateways}>
              <AppBase>
                <BreadcrumbsProvider>
                  <AppRoutes />
                </BreadcrumbsProvider>
              </AppBase>
            </AuthContextProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
