import { Check } from '@ankr.com/ui';
import { Box } from '@mui/material';

import { useLocaleMemo, useTranslation } from '../../../../../i18n';
import { GRADE } from '../../../../RollupConst';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { useRollupDeployStepsState } from '../../hooks/useRollupDeployStepsState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStepperStyles } from './useDeployRollupStepperStyles';

export function DeployRollupStepper() {
  const { classes, cx } = useDeployRollupStepperStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { grade } = useRollupDeployState();

  const rollupDeployStepsState = useRollupDeployStepsState();

  const steps = useLocaleMemo(
    () => [
      t(keys.deployRollupStepper.general),
      grade === GRADE.mainnet
        ? t(keys.deployRollupStepper.sequencer)
        : undefined,
      t(keys.deployRollupStepper.configuration),
      t(keys.deployRollupStepper.infra),
    ],
    [
      keys.deployRollupStepper.configuration,
      keys.deployRollupStepper.general,
      keys.deployRollupStepper.infra,
      keys.deployRollupStepper.sequencer,
      grade,
      t,
    ],
  );

  return (
    <Box className={classes.root}>
      <Box component="ol" className={classes.list}>
        {steps.map((step, index) => {
          const stepNumber = index + 1;
          const isPassed = stepNumber < rollupDeployStepsState.step;
          const isCurrent = stepNumber === rollupDeployStepsState.step;

          if (!step) {
            return null;
          }

          return (
            <Box
              key={`step_${step}${stepNumber}`}
              component="li"
              className={cx(
                classes.step,
                isCurrent && classes.stepCurrent,
                isPassed && classes.stepPassed,
              )}
            >
              {isPassed && <Check className={classes.icon} />}
              <Box className={classes.stepNumber} />
              <Box className={classes.stepTitle}>{step}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
