import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { tCommon } from '@ankr.com/raas-utils';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { AuthGuard } from '../guards';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { Settings } from './screens/Settings';

const PATH = '/settings';

export const SettingsRoutesConfig = createRouteConfig(
  {
    Settings: {
      path: PATH,
      generatePath: () => generatePath(PATH),
    },
  },
  PATH,
);

export function getSettingsRoutes(): JSX.Element {
  return (
    <Route
      path={SettingsRoutesConfig.root}
      element={
        <LayoutDefault title={tCommon('page-title.settings')}>
          <AuthGuard>
            <Settings />
          </AuthGuard>
        </LayoutDefault>
      }
    />
  );
}
