import { makeStyles } from 'tss-react/mui';

export const useRollupSubActionsMenuStyles = makeStyles()(theme => ({
  root: {},
  button: {
    minWidth: 'auto',
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1.5),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.dark,
    },
  },
  buttonActive: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
  },
  buttonIcon: {
    width: 20,
  },
  menuItemIcon: {
    color: theme.palette.text.secondary,
    '*:hover > &': {
      color: theme.palette.primary.main,
    },
  },
  menuItemIconDanger: {
    '*:hover > &': {
      color: theme.palette.error.main,
    },
  },
}));
