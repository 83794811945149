import React, { JSX, useMemo } from 'react';
import { AppModal, CopyButton } from '@ankr.com/raas-ui';
import { ExternalLink } from '@ankr.com/ui';
import { Box, Button } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import {
  ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL,
  ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL,
} from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { DEPLOYMENT_TYPE, IDetailsBridgeItem } from '../../../../RollupConst';
import { RollupBridgeModalTranslation } from './translation';
import { useRollupBridgeModalStyles } from './useRollupBridgeModalStyles';

interface IRollupBridgeModalProps {
  bridgeDetails: IDetailsBridgeItem;
  deploymentType?: string;
}

export function RollupBridgeModal({
  bridgeDetails,
  deploymentType,
}: IRollupBridgeModalProps): JSX.Element {
  const { classes } = useRollupBridgeModalStyles();

  const { keys, t } = useTranslation(RollupBridgeModalTranslation);

  const { open: rollupBridgeModalOpen, handleClose: handleBridgeModalClose } =
    useDialog(DialogId.RollupBridge);

  const contractsStringified = useMemo(
    () => JSON.stringify(bridgeDetails.contracts, null, 2),
    [bridgeDetails.contracts],
  );

  return (
    <AppModal
      open={rollupBridgeModalOpen}
      onClose={handleBridgeModalClose}
      title={t(keys.title)}
      classes={{ paper: classes.modalContent }}
    >
      <Box className={classes.listBody}>
        {!!bridgeDetails.bridgeUrl && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft} overflow="hidden">
              {t(keys.bridge)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <Button
                  size="extraSmall"
                  color="primary"
                  endIcon={<ExternalLink />}
                  href={bridgeDetails.bridgeUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t(keys.openBridge)}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        <Box className={classes.listRow}>
          <Box className={classes.listRowLeft} overflow="hidden">
            {t(keys.docs)}
          </Box>
          <Box className={classes.listRowRight}>
            <Box display="flex" gap={2} alignItems="center">
              <Button
                size="extraSmall"
                color="primary"
                endIcon={<ExternalLink />}
                href={
                  deploymentType === DEPLOYMENT_TYPE.optimism
                    ? ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL
                    : ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL
                }
                rel="noreferrer"
                target="_blank"
              >
                {t(keys.openDocs)}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box className={classes.listRow}>
          <Box className={classes.listRowLeft} overflow="hidden">
            {t(keys.contracts)}
          </Box>
        </Box>
        <Box className={classes.contractsBox}>{contractsStringified}</Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        <CopyButton
          text={contractsStringified}
          tooltipPlacement="top"
          renderButton={isCopied => (
            <Button
              size="large"
              fullWidth
              color={isCopied ? 'success' : 'primary'}
            >
              {isCopied ? t(keys.copied) : t(keys.copyContracts)}
            </Button>
          )}
        />

        <Button
          onClick={handleBridgeModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
