import React, { JSX } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { tCommon } from '@ankr.com/raas-utils';
import {
  ChatIcon,
  Gear,
  IntegrationsIcon,
  NavBarBilling,
  NavBarDashboard,
  NavBarDocs,
  NavBarPricing,
  NavBarRollupIcon,
  NavBarSettings,
} from '@ankr.com/ui';
import { Box, Button, Typography } from '@mui/material';

import { BillingRoutesConfig } from '../../../Billing/BillingRoutes';
import { featuresConfig } from '../../../common/const/features';
import {
  HUBSPOT_URL,
  INTEGRATIONS_PROMO_URL,
  POWERED_BY_URL,
  PROJECT_KEY,
} from '../../../common/const/values';
import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { RollupRoutesConfig } from '../../../Rollup/RollupRoutes';
import { SettingsRoutesConfig } from '../../../Settings/SettingsRoutes';
import PoweredByLogo from './assets/powered-by.svg?react';
import { useMainMenuStyles } from './useMainMenuStyles';

export function MainMenu(): JSX.Element {
  const { classes, cx } = useMainMenuStyles();

  const { pathname } = useLocation();

  return (
    <Box className={classes.root}>
      <Box className={classes.top}>
        <Button
          component={Link}
          to={DashboardRoutesConfig.Dashboard.generatePath()}
          variant="text"
          className={cx(
            classes.button,
            pathname === DashboardRoutesConfig.Dashboard.generatePath() &&
              classes.active,
          )}
          startIcon={<NavBarDashboard />}
        >
          {tCommon('navigation.dashboard')}
        </Button>

        <Typography
          variant="subtitle3"
          component={Box}
          className={classes.title}
        >
          {tCommon('navigation.new-projects')}
        </Typography>

        <Button
          component={Link}
          to={RollupRoutesConfig.Deploy.generatePath()}
          variant="text"
          className={cx(
            classes.button,
            (pathname === RollupRoutesConfig.Deploy.generatePath() ||
              pathname === `${RollupRoutesConfig.Deploy.generatePath()}/`) &&
              classes.active,
          )}
          startIcon={<NavBarRollupIcon />}
        >
          {tCommon('navigation.deploy-rollup')}
        </Button>

        {featuresConfig.showLinksToNonExistPages && (
          <Button
            component={Link}
            to={DashboardRoutesConfig.Dashboard.generatePath()}
            variant="text"
            className={classes.button}
            startIcon={<NavBarSettings />}
          >
            {tCommon('navigation.deploy-sidechain')}
          </Button>
        )}

        <hr className={classes.hr} />

        {featuresConfig.showLinksToNonExistPages && (
          <Button
            component={Link}
            to={DashboardRoutesConfig.Dashboard.generatePath()}
            variant="text"
            className={classes.button}
            startIcon={<NavBarPricing />}
          >
            {tCommon('navigation.pricing')}
          </Button>
        )}

        <Button
          component={Link}
          to={BillingRoutesConfig.Billing.generatePath()}
          variant="text"
          className={cx(
            classes.button,
            (pathname === BillingRoutesConfig.Billing.generatePath() ||
              pathname === `${BillingRoutesConfig.Billing.generatePath()}/`) &&
              classes.active,
          )}
          startIcon={<NavBarBilling />}
        >
          {tCommon('navigation.billing')}
        </Button>

        {!!INTEGRATIONS_PROMO_URL && (
          <Button
            component={Link}
            to={INTEGRATIONS_PROMO_URL}
            variant="text"
            className={classes.button}
            startIcon={<IntegrationsIcon />}
            rel="noreferrer"
            target="_blank"
          >
            {tCommon('navigation.integrations')}
          </Button>
        )}

        {featuresConfig.showLinksToNonExistPages && (
          <Button
            component={Link}
            to={DashboardRoutesConfig.Dashboard.generatePath()}
            variant="text"
            className={classes.button}
            startIcon={<NavBarDocs />}
          >
            {tCommon('navigation.docs')}
          </Button>
        )}
      </Box>

      <Box className={classes.bottom}>
        <Button
          component={Link}
          to={HUBSPOT_URL}
          variant="text"
          className={classes.button}
          startIcon={<ChatIcon />}
          rel="noreferrer"
          target="_blank"
        >
          {tCommon('navigation.lets-talk')}
        </Button>

        <Button
          component={Link}
          to={SettingsRoutesConfig.Settings.generatePath()}
          variant="text"
          className={cx(
            classes.button,
            (pathname === SettingsRoutesConfig.Settings.generatePath() ||
              pathname ===
                `${SettingsRoutesConfig.Settings.generatePath()}/`) &&
              classes.active,
          )}
          startIcon={<Gear />}
        >
          {tCommon('navigation.settings')}
        </Button>

        {PROJECT_KEY !== 'asphere' && (
          <Button
            component={Link}
            to={POWERED_BY_URL}
            variant="text"
            className={cx(classes.button, classes.poweredByButton)}
            rel="noreferrer"
            target="_blank"
          >
            <PoweredByLogo className={classes.poweredByLogo} />
          </Button>
        )}
      </Box>
    </Box>
  );
}
