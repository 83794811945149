import React, { JSX, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppModal } from '@ankr.com/raas-ui';
import { ExternalLink, LoadingButton } from '@ankr.com/ui';
import { Alert, Box, Button } from '@mui/material';

import { useAppDispatch } from '../../../../../../store/useAppDispatch';
import { useGetSignatureMutation } from '../../../../../common/actions/getSignature';
import { DialogId } from '../../../../../common/actions/openDialog';
import {
  ANKR_ETH_FOR_TESTNET_AMOUNT,
  STAKING_DOCS_URL,
  STAKING_URL,
} from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { getToken } from '../../../../../common/utils/getToken';
import { useTranslation } from '../../../../../i18n';
import {
  DECLARED_TOKEN,
  IDeployRollupFormPayload,
} from '../../../../RollupConst';
import { RollupRoutesConfig } from '../../../../RollupRoutes';
import { usePostRollupDeployMutation } from '../../api/postRollupDeploy';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { resetFormData } from '../../store/rollupDeploySlice';
import { resetStepsData } from '../../store/rollupDeployStepsSlice';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

interface IDeployRollupTestnetProveModalProps {
  rollupDeployState: IDeployRollupFormPayload;
}

export function DeployRollupTestnetProveModal({
  rollupDeployState,
}: IDeployRollupTestnetProveModalProps): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const dispatch = useAppDispatch();

  const { dispatchData } = useDispatchRollupDeployData();

  const navigate = useNavigate();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const [
    getSignature,
    {
      data: signatureData,
      isLoading: isSignatureLoading,
      reset: resetSignature,
    },
  ] = useGetSignatureMutation();

  const [
    postRollupDeployment,
    {
      data: rollupData,
      isLoading: isPostRollupDeploymentLoading,
      isSuccess: isPostRollupDeploymentSuccess,
    },
  ] = usePostRollupDeployMutation();

  const {
    open: rollupTestnetProveOpen,
    handleClose: handleRollupTestnetProveClose,
  } = useDialog(DialogId.RollupTestnetProve);

  const { handleOpen: handleRollupConfirmOpen } = useDialog(
    DialogId.RollupConfirm,
  );

  const handleCancel = useCallback(() => {
    handleRollupTestnetProveClose();
    handleRollupConfirmOpen();
  }, [handleRollupConfirmOpen, handleRollupTestnetProveClose]);

  const handleProve = useCallback(async () => {
    if (!signatureData?.signature || !signatureData?.address) {
      await getSignature();
    }
  }, [getSignature, signatureData?.address, signatureData?.signature]);

  useEffect(() => {
    if (signatureData?.signature && signatureData?.address) {
      dispatchData({
        signature: signatureData?.signature,
        userAddress: signatureData?.address,
      });
    }
  }, [dispatchData, signatureData?.address, signatureData?.signature]);

  const handleConfirm = useCallback(async () => {
    if (signatureData?.signature && signatureData?.address) {
      await postRollupDeployment(rollupDeployState);
    }
  }, [
    postRollupDeployment,
    rollupDeployState,
    signatureData?.address,
    signatureData?.signature,
  ]);

  useEffect(() => {
    if (isPostRollupDeploymentSuccess) {
      dispatch(resetFormData());
      dispatch(resetStepsData());
      handleRollupTestnetProveClose();
      resetSignature();
      if (rollupData?.deployment?.uuid) {
        navigate(
          RollupRoutesConfig.Rollup.generatePath({
            uuid: rollupData.deployment.uuid,
            success: true,
          }),
        );
      }
    }
  }, [
    dispatch,
    handleRollupTestnetProveClose,
    isPostRollupDeploymentSuccess,
    navigate,
    resetSignature,
    rollupData?.deployment?.uuid,
  ]);

  return (
    <AppModal
      open={rollupTestnetProveOpen}
      onClose={handleCancel}
      title={t(keys.deployRollupProveModal.title, {
        token: getToken({ value: DECLARED_TOKEN.ankreth }).name,
      })}
      classes={{ paper: classes.modalContent }}
    >
      <Box mt={3}>
        <Alert severity="info" sx={{ width: '100%' }}>
          {t(
            keys.deployRollupProveModal.hint,
            {
              amount: ANKR_ETH_FOR_TESTNET_AMOUNT,
              token: getToken({ value: DECLARED_TOKEN.ankreth }).name,
              stakingDocsUrl: STAKING_DOCS_URL,
            },
            true,
          )}
          <Box ml={-3} mt={3}>
            <Button
              variant="text"
              size="small"
              color="primary"
              endIcon={<ExternalLink />}
              href={STAKING_URL}
              rel="noreferrer"
              target="_blank"
            >
              {t(keys.deployRollupProveModal.stakeButton)}
            </Button>
          </Box>
        </Alert>
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        {signatureData?.signature ? (
          <LoadingButton
            onClick={handleConfirm}
            loading={isPostRollupDeploymentLoading}
            size="large"
            fullWidth
          >
            {t(keys.deployRollupProveModal.confirm)}
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={handleProve}
            loading={isSignatureLoading}
            size="large"
            fullWidth
          >
            {t(keys.deployRollupProveModal.prove)}
          </LoadingButton>
        )}

        <Button
          onClick={handleCancel}
          disabled={isPostRollupDeploymentLoading || isSignatureLoading}
          size="large"
          fullWidth
          variant="outlined"
        >
          {t(keys.deployRollupProveModal.cancel)}
        </Button>
      </Box>
    </AppModal>
  );
}
