import { Locale } from '../../../i18n';

export const rollupPayModalTranslation = {
  [Locale.en]: {
    title: 'Summary',
    subtitle: 'Please review your chosen specifications.',
    confirm: 'Confirm',
    confirmAndPay: 'Confirm and Pay {price}',
    cancel: 'Cancel',
    poweredBy: 'Powered by',

    stack: 'Stack',
    chainId: 'Chain ID',
    networkName: 'Rollup Name',
    grade: 'Network',
    DAL: 'Data Availability Layer',
    sequencerAddress: 'Sequencer Address',
    sequencerUrl: 'Sequencer URL',
    gasToken: 'Gas Token',
    success: 'Success',
    goToDashBoard: 'Go to Dashboard',

    depositHint:
      'To finalize rollup deployment, you must make a security deposit of {amount} to your sequencer (subject to slashing rules).',
  },
};
