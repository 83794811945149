import React, { JSX } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';

import { getBillingRoutes } from './modules/Billing/BillingRoutes';
import { getDashboardRoutes } from './modules/Dashboard/DashboardRoutes';
import { LayoutDefault } from './modules/layout/components/LayoutDefault';
import { getRollupRoutes } from './modules/Rollup/RollupRoutes';
import { getSettingsRoutes } from './modules/Settings/SettingsRoutes';

export function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {getDashboardRoutes()}
      {getRollupRoutes()}
      {getBillingRoutes()}
      {getSettingsRoutes()}
      <Route
        path="*"
        element={
          <LayoutDefault title={tCommon('page-title.page-not-found')}>
            <PageNotFound />
          </LayoutDefault>
        }
      />
    </Routes>
  );
}
