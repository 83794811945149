import { Locale } from '../../../i18n';

export const gradeTranslation = {
  [Locale.en]: {
    grade: {
      mainnet: 'Mainnet',
      testnet: 'Testnet',
    },
  },
};
