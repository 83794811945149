import {
  HEADER_HEIGHT,
  MIN_LAYOUT_WIDTH,
  SIDEBAR_WIDTH,
} from '@ankr.com/raas-themes';
import { makeStyles } from 'tss-react/mui';

export const useLayoutDefaultStyles = makeStyles()(theme => ({
  root: {
    minWidth: MIN_LAYOUT_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    paddingTop: HEADER_HEIGHT,
  },

  main: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  sidebarWrap: {
    width: SIDEBAR_WIDTH,
    minWidth: SIDEBAR_WIDTH,
  },
  mainContent: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
