import { buttonBaseClasses, svgIconClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useBreadcrumbsStyles = makeStyles()(theme => ({
  separator: {
    color: theme.palette.text.disabled,
    fontSize: 10,
    display: 'flex',
  },
  muiSeparator: {
    margin: 0,
  },
  breadcrumbsRoot: {},
  breadcrumbs: {
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    alignItems: 'center',
    [`& > li > .${buttonBaseClasses.root} > .${svgIconClasses.root}`]: {
      fill: 'currentColor',
    },
  },
  breadcrumbsLi: {},
  item: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  itemLast: {
    color: theme.palette.text.disabled,
  },
}));
