import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import { IAccountInfo, IAccountInfoFormApi } from './types';

export const { usePostAccountInfoMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    postAccountInfo: build.mutation<IAccountInfoFormApi, IAccountInfo>({
      queryFn: queryFnNotifyWrapper<IAccountInfo, never, IAccountInfoFormApi>(
        async payload => {
          const mappedPayload: IAccountInfoFormApi = {
            company_name: payload.companyName?.trim(),
            company_info: payload.companyInfo?.trim(),
            company_twitter: payload.companyTwitter?.trim(),
          };
          const res = await Gateways.auth.api.patch(
            '/api/v1/user/details',
            JSON.stringify(mappedPayload),
          );

          return { data: res.data };
        },
      ),
      invalidatesTags: [cacheTags.accountInfo],
    }),
  }),
});
