import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { tCommon } from '@ankr.com/raas-utils';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { AuthGuard } from '../guards';
import { AccountInfoGuard } from '../guards/AccountInfoGuard';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { DeployRollup } from './screens/DeployRollup';
import { Rollup } from './screens/Rollup/Rollup';

const PATH_ROLLUP = '/rollup';
const PATH_DEPLOY = '/rollup/deploy';

interface IRollupRouteProps {
  uuid: string;
  success?: boolean;
  failed?: boolean;
}

export const RollupRoutesConfig = createRouteConfig(
  {
    Rollup: {
      path: PATH_ROLLUP,
      generatePath: ({ uuid, success, failed }: IRollupRouteProps) =>
        generatePath(
          `${PATH_ROLLUP}/?uuid=${uuid}${success ? '&success=true' : ''}${failed ? '&failed=true' : ''}`,
        ),
    },
    Deploy: {
      path: PATH_DEPLOY,
      generatePath: () => generatePath(PATH_DEPLOY),
    },
  },
  PATH_ROLLUP,
);

export function getRollupRoutes(): JSX.Element {
  return (
    <>
      <Route
        path={RollupRoutesConfig.Rollup.path}
        element={
          <LayoutDefault title={tCommon('page-title.rollup')}>
            <AuthGuard>
              <AccountInfoGuard>
                <Rollup />
              </AccountInfoGuard>
            </AuthGuard>
          </LayoutDefault>
        }
      />
      <Route
        path={RollupRoutesConfig.Deploy.path}
        element={
          <LayoutDefault title={tCommon('page-title.deploy-rollup')}>
            <AuthGuard>
              <AccountInfoGuard>
                <DeployRollup />
              </AccountInfoGuard>
            </AuthGuard>
          </LayoutDefault>
        }
      />
    </>
  );
}
