import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../api';
import { mapDeploymentFromApi } from '../../common/utils/mapDeploymentFromApi';
import { IRollupDeployed, IRollupDeployedApi } from '../../Rollup/RollupConst';

export const { useGetRollupDeploysQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getRollupDeploys: build.query<IRollupDeployed[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IRollupDeployed[]>(
        async () => {
          const { data }: { data: IRollupDeployedApi[] } =
            await Gateways.auth.api.get('/api/v1/deployments');

          const mappedData: IRollupDeployed[] = data
            // TODO: it is better to sort this data on the backend side
            ?.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime(),
            )
            .map(deployment => mapDeploymentFromApi({ deployment }));

          return { data: mappedData };
        },
      ),
      providesTags: [cacheTags.rollups],
    }),
  }),
});
