import { Locale } from '../../../i18n';

export const planCancellationModalTranslation = {
  [Locale.en]: {
    title: 'Are you sure?',
    hint: 'If you are sure you want to cancel your plan, please contact our Sales team below with your request.',
    contact: 'Contact Sales',
    close: 'Close',
  },
};
