import { getMainThemeOptions } from '@ankr.com/raas-themes';
import { createTheme, PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'light',

  primary: {
    main: '#356DF3',
    dark: '#2A5BD1',
    light: '#E1E9FD',
    contrastText: '#ffffff',
  },

  secondary: {
    main: '#FFFFFF',
    dark: '#E7EBF3',
    light: '#E7EBF3',
    contrastText: '#82899A',
  },

  info: {
    main: '#356DF3',
    dark: '#2A5BD1',
    light: '#E1E9FD',
    contrastText: '#ffffff',
  },

  error: {
    main: '#D22C54',
    dark: '#B32547',
    light: '#F8DFE5',
    contrastText: '#FFFFFF',
  },

  warning: {
    main: '#EEA941',
    dark: '#D89A3B',
    light: '#FCF2E2',
    contrastText: '#1F2226',
  },

  success: {
    main: '#3AC090',
    dark: '#31A37A',
    light: '#E1F6EE',
    contrastText: '#FFFFFF',
  },

  highlight: {
    main: '#F4E9D0',
    dark: '#FFA030',
  },

  background: {
    default: '#F2F5FA',
    paper: '#ffffff',
  },

  grey: {
    100: '#E7EBF3',
    200: '#DFE3EB',
    300: '#BFC6D0',
    400: '#A7AFC0',
    500: '#9AA1B0',
    600: '#82899A',
    700: '#585E6B',
    800: '#2E343C',
    900: '#1F2226',
  },

  yellow: {
    main: '#FFB800',
    dark: '#D9AD00',
    light: '#FFF7D9',
  },

  teal: {
    main: '#3FDDDD',
    dark: '#36BCBC',
    light: '#E2FAFA',
  },

  indigo: {
    main: '#5856D6',
    dark: '#4B49B6',
    light: '#E6E6F9',
  },

  purple: {
    main: '#AF52DE',
    dark: '#9546BD',
    light: '#F3E5FA',
  },

  link: {
    main: '#356DF3',
  },

  text: {
    primary: '#1F2226',
    secondary: '#82899A',
    disabled: '#A7AFC0',
  },

  divider: '#E7EBF3',

  common: {
    black: '#000000',
    white: '#ffffff',
  },
};

const mainThemeOptions = getMainThemeOptions({ palette });

export const mainTheme = createTheme({
  ...mainThemeOptions,
  components: {
    ...mainThemeOptions.components,
    MuiIconButton: {
      ...mainThemeOptions.components?.MuiIconButton,
      styleOverrides: {
        ...mainThemeOptions.components?.MuiIconButton?.styleOverrides,
        colorInherit: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...mainThemeOptions.components?.MuiIconButton?.styleOverrides
            ?.colorInherit,
          borderColor: palette.background?.default,
          color: palette.grey?.['400'],
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: palette.grey?.['300'],
          },
        },
      },
    },
  },
});
