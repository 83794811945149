import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberFormatField } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import {
  CancelledIcon,
  ExternalLink,
  OverlaySpinner,
  Question,
  Success,
  TextField,
} from '@ankr.com/ui';
import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { featuresConfig } from '../../../../../common/const/features';
import {
  CUSTOM_GAS_TOKEN_DOCS_URL,
  MAX_CHAIN_ID,
  PROJECT_TITLE,
} from '../../../../../common/const/values';
import { getChain } from '../../../../../common/utils/getChain';
import { getToken } from '../../../../../common/utils/getToken';
import { useLocale, useLocaleMemo, useTranslation } from '../../../../../i18n';
import {
  DECLARED_CHAIN,
  DECLARED_TOKEN,
  deployRollupInitialState,
  GRADE,
  IDeployRollupFormConfigurationPayload,
} from '../../../../RollupConst';
import { useGetDALsQuery } from '../../api/getDALs';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';
import { useConfigurationStepValidation } from './hooks/useConfigurationStepValidation';

export function DeployRollupFormConfiguration(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { locale } = useLocale();

  const rollupDeployState = useRollupDeployState();

  const { data: DALs } = useGetDALsQuery(
    {
      grade: rollupDeployState.grade,
      stack: rollupDeployState.stack,
    },
    {
      skip: !rollupDeployState.grade || !rollupDeployState.stack,
    },
  );

  const currentDAL = useMemo(
    () =>
      DALs?.find(DAL => DAL.uuid === rollupDeployState.dataAvailabilityLayer),
    [DALs, rollupDeployState.dataAvailabilityLayer],
  );

  const { dispatchData } = useDispatchRollupDeployData();

  const { control, handleSubmit, getValues, watch, resetField } =
    useForm<IDeployRollupFormConfigurationPayload>({
      defaultValues: {
        chainId: rollupDeployState.chainId,
        networkName: rollupDeployState.networkName,
        gasToken: rollupDeployState.gasToken,
        gasTokenAddress: rollupDeployState.gasTokenAddress,
        optionalSettings: rollupDeployState.optionalSettings,
      },
      mode: 'onChange',
    });

  const isMainnet = useMemo(
    () => rollupDeployState.grade === GRADE.mainnet,
    [rollupDeployState.grade],
  );

  // TODO: need to get l1chain from the backend by selected grade and stack or DAL
  const chain = useMemo(
    () =>
      getChain({
        value: isMainnet ? DECLARED_CHAIN.ethereum : DECLARED_CHAIN.holesky,
      }).name,
    [isMainnet],
  );

  const isCustomGasTokenSelected = watch('gasToken') === DECLARED_TOKEN.custom;

  useEffect(() => {
    if (
      !currentDAL?.gasTokens?.some(
        gasToken => gasToken.key === DECLARED_TOKEN.custom,
      ) &&
      isCustomGasTokenSelected
    ) {
      resetField('gasToken', {
        defaultValue: deployRollupInitialState.gasToken,
      });
      dispatchData({ gasToken: deployRollupInitialState.gasToken });
    }
  }, [
    currentDAL?.gasTokens,
    dispatchData,
    isCustomGasTokenSelected,
    resetField,
  ]);

  useEffect(() => {
    if (!isCustomGasTokenSelected) {
      resetField('gasTokenAddress', {
        defaultValue: deployRollupInitialState.gasTokenAddress,
      });
      dispatchData({
        gasTokenAddress: deployRollupInitialState.gasTokenAddress,
      });
    }
  }, [dispatchData, isCustomGasTokenSelected, resetField]);

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormConfigurationPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  const {
    isChainIDUsedFetching,
    validateChainID,
    validateAddress,
    isAddressValidationFetching,
    gasTokenAddressValidation,
  } = useConfigurationStepValidation();

  const gasTokenAddressValidationLevels = useLocaleMemo(() => {
    const getStatusIcon = (status: boolean | undefined) => {
      if (status) {
        return (
          <Success
            className={cx(
              classes.customGasTokenValLevelIcon,
              classes.customGasTokenValLevelIconSuccess,
            )}
          />
        );
      }
      if (status === false) {
        return (
          <CancelledIcon
            className={cx(
              classes.customGasTokenValLevelIcon,
              classes.customGasTokenValLevelIconError,
            )}
          />
        );
      }
      return <CancelledIcon className={classes.customGasTokenValLevelIcon} />;
    };

    return (
      <Stack direction="column" justifyContent="flex-start" spacing={2} mt={2}>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isValidAddress)}
          {t(keys.deployRollupConfigurationToken.isValidAddress)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isErc20)}
          {t(keys.deployRollupConfigurationToken.isErc20)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.is18Decimals)}
          {t(keys.deployRollupConfigurationToken.is18Decimals)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isNonRebasing)}
          {t(keys.deployRollupConfigurationToken.isNonRebasing)}
        </Typography>
      </Stack>
    );
  }, [
    classes.customGasTokenValLevel,
    classes.customGasTokenValLevelIcon,
    classes.customGasTokenValLevelIconError,
    classes.customGasTokenValLevelIconSuccess,
    cx,
    gasTokenAddressValidation?.is18Decimals,
    gasTokenAddressValidation?.isErc20,
    gasTokenAddressValidation?.isNonRebasing,
    gasTokenAddressValidation?.isValidAddress,
    keys.deployRollupConfigurationToken.is18Decimals,
    keys.deployRollupConfigurationToken.isErc20,
    keys.deployRollupConfigurationToken.isNonRebasing,
    keys.deployRollupConfigurationToken.isValidAddress,
    t,
  ]);

  const gasTokenList = useLocaleMemo(() => {
    return currentDAL?.gasTokens?.map(gasToken => {
      const { image, name } = getToken({
        value: gasToken.key,
        imageClassName: classes.gasTokenIcon,
      });
      if (gasToken.key === DECLARED_TOKEN.custom) {
        return {
          label: (
            <Box>
              {name}
              <Typography
                variant="body3"
                className={classes.textDisabled}
                component="div"
              >
                {t(keys.deployRollupConfigurationProps.customGasTokenHint)}
              </Typography>
            </Box>
          ),
          key: gasToken.key,
        };
      }

      return {
        label: (
          <Box>
            {image}
            {name}
          </Box>
        ),
        key: gasToken.key,
      };
    });
  }, [
    currentDAL?.gasTokens,
    classes.gasTokenIcon,
    classes.textDisabled,
    t,
    keys.deployRollupConfigurationProps.customGasTokenHint,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="chainId"
              rules={{
                required: tCommon('validation.required'),
                validate: v => validateChainID(Number(v)),
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberFormatField
                  {...field}
                  label={
                    <Box className={classes.inputLabelWrap}>
                      {t(keys.deployRollupConfigurationProps.chainId)}
                      <Tooltip
                        title={t(
                          keys.deployRollupConfigurationProps.chainIdTooltip,
                        )}
                      >
                        <Question className={classes.questionIcon} />
                      </Tooltip>
                    </Box>
                  }
                  helperText={t(
                    keys.deployRollupConfigurationProps.chainIdHint,
                  )}
                  fullWidth
                  autoFocus
                  allowNegative={false}
                  thousandSeparator=""
                  decimalScale={0}
                  endLabel={
                    isChainIDUsedFetching && (
                      <OverlaySpinner
                        size={20}
                        classes={{
                          root: classes.validationSpinner,
                        }}
                      />
                    )
                  }
                  locale={locale}
                  minValue={0}
                  maxValue={MAX_CHAIN_ID}
                  inputMode="numeric"
                  errorText={error?.message}
                />
              )}
            />
          </Box>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="networkName"
              rules={{
                required: tCommon('validation.required'),
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  type="url"
                  label={t(keys.deployRollupConfigurationProps.networkName)}
                />
              )}
            />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionTitleWrap}>
            {isMainnet && featuresConfig.showAnkrEthGasToken && (
              <Alert severity="info" className={classes.alertBlock}>
                {t(keys.deployRollupConfigurationToken.tokensHint, {
                  project: PROJECT_TITLE,
                })}
              </Alert>
            )}

            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {t(keys.deployRollupConfigurationToken.title)}
            </Typography>
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {isMainnet &&
                featuresConfig.showAnkrEthGasToken &&
                t(keys.deployRollupConfigurationToken.subtitleMainnet)}
            </Typography>
          </Box>

          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="gasToken"
              rules={{
                required: tCommon('validation.required-one'),
              }}
              control={control}
              render={({ field, fieldState }) => (
                <RadioGroup
                  name="gasToken"
                  className={classes.radioGroup}
                  value={field.value}
                >
                  {gasTokenList?.map(item => {
                    if (!item) {
                      return null;
                    }

                    return (
                      <FormControlLabel
                        key={`gasToken_${item.key}`}
                        control={
                          <Radio
                            {...field}
                            size="small"
                            value={item.key}
                            disableFocusRipple
                          />
                        }
                        label={item.label}
                      />
                    );
                  })}

                  {!!fieldState.error?.message && (
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </RadioGroup>
              )}
            />
          </Box>

          {isCustomGasTokenSelected && (
            <Box className={classes.sectionHalfWrap} mt={6}>
              <Controller
                name="gasTokenAddress"
                rules={{
                  validate: v => validateAddress(v),
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={
                      <Box
                        component="span"
                        className={classes.customTextFieldHint}
                      >
                        {t(
                          keys.deployRollupConfigurationToken
                            .customGasTokenHint,
                          { chain },
                          true,
                        )}
                      </Box>
                    }
                    error={!!error}
                    onChange={onChange}
                    endLabel={
                      isAddressValidationFetching && (
                        <OverlaySpinner
                          size={20}
                          classes={{
                            root: classes.validationSpinner,
                          }}
                        />
                      )
                    }
                    value={value}
                    fullWidth
                    label={t(
                      keys.deployRollupConfigurationProps.customGasTokenAddress,
                    )}
                  />
                )}
              />
              {gasTokenAddressValidationLevels}
            </Box>
          )}

          {!!CUSTOM_GAS_TOKEN_DOCS_URL &&
            currentDAL?.gasTokens?.some(
              gasToken => gasToken.key === DECLARED_TOKEN.custom,
            ) && (
              <Typography
                className={classes.sectionSubtitle}
                variant="body3"
                component="div"
                mt={6}
              >
                {t(keys.deployRollupConfigurationToken.wantAddTokenHint)}
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  endIcon={<ExternalLink />}
                  href={CUSTOM_GAS_TOKEN_DOCS_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t(keys.deployRollup.viewRequirements)}
                </Button>
              </Typography>
            )}
        </Box>
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
