import { Locale } from '../../../../../i18n';

export const rollupDepositModalTranslation = {
  [Locale.en]: {
    title: 'Deposit',
    subtitle: 'Deposit {amount} to finish rollup deployment',
    network: 'Network',
    amount: '{token} Amount',
    currencyAmount: '{ethDeposit} {token}',
    address: 'Your Personal Deposit Address',
    alert: 'All fees will be paid from this address.',
    copyAddress: 'Copy Address',
    copied: 'Copied!',
    close: 'Close',
  },
};
