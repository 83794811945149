import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deployRollupInitialState,
  IDeployRollupFormPayload,
} from '../../../RollupConst';

export const rollupDeploySlice = createSlice({
  name: 'rollupDeploy',
  initialState: deployRollupInitialState,
  reducers: {
    updateFormData: (
      state,
      action: PayloadAction<IDeployRollupFormPayload>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetFormData: () => deployRollupInitialState,
  },
});

export const { updateFormData, resetFormData } = rollupDeploySlice.actions;
