import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { tCommon } from '@ankr.com/raas-utils';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { AuthGuard } from '../guards';
import { AccountInfoGuard } from '../guards/AccountInfoGuard';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { Dashboard } from './screens/Dashboard';

const PATH = '/';

export const DashboardRoutesConfig = createRouteConfig(
  {
    Dashboard: {
      path: PATH,
      generatePath: () => generatePath(PATH),
    },
  },
  PATH,
);

export function getDashboardRoutes(): JSX.Element {
  return (
    <Route
      path={DashboardRoutesConfig.root}
      element={
        <LayoutDefault title={tCommon('page-title.dashboard')}>
          <AuthGuard>
            <AccountInfoGuard>
              <Dashboard />
            </AccountInfoGuard>
          </AuthGuard>
        </LayoutDefault>
      }
    />
  );
}
