import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { tCommon, truncateWalletAddress } from '@ankr.com/raas-utils';
import { LoadingButton } from '@ankr.com/ui';
import { Alert, Box, Button, Typography } from '@mui/material';

import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useTranslation } from '../../../i18n';
import {
  DECLARED_TOKEN,
  GRADE,
  IRollupDeployed,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../Rollup/RollupConst';
import { useExtendRollupMutation } from '../../../Rollup/screens/Rollup/api/extendRollup';
import { DialogId } from '../../actions/openDialog';
import stripeLogoUrl from '../../assets/stripeLogo.png';
import { useDepositsForPlan } from '../../hooks/useDepositsForPlan';
import { useDialog } from '../../hooks/useDialog';
import { getToken } from '../../utils/getToken';
import { GradeChip } from '../GradeChip';
import { StackDALIcon } from '../StackDALIcon';
import { rollupPayModalTranslation } from './translation';
import { useRollupPayModalStyles } from './useRollupPayModalStyles';

interface IDeployRollupConfirmModalProps {
  rollup?: IRollupDeployed;
}

export function RollupPayModal({
  rollup,
}: IDeployRollupConfirmModalProps): JSX.Element {
  const { classes } = useRollupPayModalStyles();

  const navigate = useNavigate();

  const { keys, t } = useTranslation(rollupPayModalTranslation);

  const currentRPCAmount = useMemo(
    () => rollup?.plan?.settings?.find(setting => setting.key === 'rpc_amount'),
    [rollup?.plan?.settings],
  );

  const { imageSrc: gasTokenLogo, name: gasTokenName } = getToken({
    value: rollup?.gasToken,
  });

  const { open: rollupPayOpen, handleClose: handleRollupPayClose } = useDialog(
    DialogId.RollupPay,
  );

  const [
    extendRollup,
    {
      data: extendRollupResult,
      isLoading: isExtendRollupLoading,
      isSuccess: isExtendRollupSuccess,
    },
  ] = useExtendRollupMutation();

  useEffect(() => {
    if (isExtendRollupSuccess) {
      if (extendRollupResult?.subscription?.url) {
        handleRollupPayClose();
        window.location.assign(extendRollupResult.subscription.url);
      } else {
        handleRollupPayClose();
        navigate(DashboardRoutesConfig.Dashboard.generatePath());
      }
    }
  }, [
    extendRollupResult?.subscription?.url,
    handleRollupPayClose,
    isExtendRollupSuccess,
    navigate,
  ]);

  const onSubmitClick = useCallback(async () => {
    await extendRollup({ uuid: rollup?.uuid });
  }, [extendRollup, rollup?.uuid]);

  const depositAmount = useDepositsForPlan({
    deposits: rollup?.plan?.deposits,
    gasTokenType: rollup?.gasToken,
  });

  const depositsAmount = useMemo(() => {
    if (rollup?.deposits?.parsedForUI.amountsWithToken) {
      return rollup?.deposits?.parsedForUI.amountsWithToken?.join(
        tCommon('common.plus-sign-spaced'),
      );
    }
    return depositAmount;
  }, [depositAmount, rollup?.deposits?.parsedForUI.amountsWithToken]);

  return (
    <AppModal
      open={rollupPayOpen}
      onClose={handleRollupPayClose}
      title={t(keys.title)}
      subtitle={t(keys.subtitle)}
      classes={{ paper: classes.modalContent }}
    >
      <Box className={classes.listBody}>
        {rollup?.plan?.stack && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.stack)}</Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <StackDALIcon
                  keyString={rollup?.plan?.stack.key}
                  title={rollup?.plan?.stack.title}
                  className={classes.logoImg}
                />
                {rollup?.plan?.stack.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollup?.chainId && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.chainId)}</Box>
            <Box className={classes.listRowRight}>{rollup?.chainId}</Box>
          </Box>
        )}

        {rollup?.networkName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.networkName)}</Box>
            <Box className={classes.listRowRight}>{rollup?.networkName}</Box>
          </Box>
        )}

        {rollup?.plan?.grade && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.grade)}</Box>
            <Box className={classes.listRowRight}>
              <GradeChip grade={rollup?.plan?.grade} />
            </Box>
          </Box>
        )}

        {rollup?.dataAvailabilityLayer && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.DAL)}</Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <StackDALIcon
                  keyString={rollup?.dataAvailabilityLayer.key}
                  title={rollup?.dataAvailabilityLayer.title}
                  className={classes.logoImg}
                  isDAL
                />
                {rollup?.dataAvailabilityLayer.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollup?.sequencerAddress && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.sequencerAddress)}
            </Box>
            <Box className={classes.listRowRight}>
              {truncateWalletAddress(rollup?.sequencerAddress)}
            </Box>
          </Box>
        )}

        {rollup?.sequencerUrl && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.sequencerUrl)}</Box>
            <Box className={classes.listRowRight}>{rollup?.sequencerUrl}</Box>
          </Box>
        )}

        {gasTokenLogo && gasTokenName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.gasToken)}</Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={gasTokenLogo}
                  alt={gasTokenName}
                  className={classes.logoImg}
                />
                {gasTokenName}
              </Box>
            </Box>
          </Box>
        )}

        {currentRPCAmount && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{currentRPCAmount.title}</Box>
            <Box className={classes.listRowRight}>{currentRPCAmount.value}</Box>
          </Box>
        )}
      </Box>

      {((rollup?.plan?.grade === GRADE.mainnet &&
        rollup?.sequencerManagementType ===
          SEQUENCER_MANAGEMENT_TYPE.product) ||
        rollup?.gasToken === DECLARED_TOKEN.custom) && (
        <Box mt={4}>
          <Alert severity="info" sx={{ width: '100%' }}>
            {t(keys.depositHint, {
              amount: depositsAmount,
            })}
          </Alert>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        <LoadingButton
          onClick={onSubmitClick}
          loading={isExtendRollupLoading}
          size="large"
          fullWidth
        >
          {rollup?.plan?.grade === GRADE.mainnet && rollup?.plan?.price
            ? t(keys.confirmAndPay, {
                price: tCommon('number.price', {
                  value: rollup?.plan?.price,
                }),
              })
            : t(keys.confirm)}
        </LoadingButton>
        <Button
          onClick={handleRollupPayClose}
          disabled={isExtendRollupLoading}
          size="large"
          fullWidth
          variant="outlined"
        >
          {t(keys.cancel)}
        </Button>
        {rollup?.plan?.grade === GRADE.mainnet && rollup?.plan?.price && (
          <Box mt={4} textAlign="center">
            <Typography variant="body4">
              {t(keys.poweredBy)}
              <ImageX imgUrl={stripeLogoUrl} className={classes.vAlignMiddle} />
            </Typography>
          </Box>
        )}
      </Box>
    </AppModal>
  );
}
