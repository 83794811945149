import React, { JSX, useMemo } from 'react';
import { QueryEmpty } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Check, Chip } from '@ankr.com/ui';
import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { GRADE } from '../../../../RollupConst';
import { useGetPlansQuery } from '../../api/getPlans';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupConfirmModal } from '../DeployRollupConfirmModal';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';
import { DeployRollupTestnetProveModal } from '../DeployRollupTestnetProveModal';

export function DeployRollupFormInfraPackage(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { handleOpen: handleRollupPlansOpen } = useDialog(DialogId.RollupPlans);

  const rollupDeployState = useRollupDeployState();

  const { data: plans } = useGetPlansQuery(
    {
      grade: rollupDeployState.grade,
      stack: rollupDeployState.stack,
    },
    {
      skip: !rollupDeployState.grade || !rollupDeployState.stack,
    },
  );

  const currentPlan = useMemo(
    () => plans?.find(plan => plan.uuid === rollupDeployState.planUuid),
    [plans, rollupDeployState.planUuid],
  );

  return (
    <>
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.titleWrap}>
            <Box display="flex" gap={2}>
              <Typography
                className={classes.sectionTitle}
                variant="h6"
                component="div"
              >
                {currentPlan?.title}
              </Typography>
              {currentPlan?.price && !currentPlan?.price.isZero() ? (
                <Chip
                  label={t(
                    'number.price-per-month',
                    { value: currentPlan?.price },
                    true,
                  )}
                  color="primary"
                />
              ) : (
                <Chip label={tCommon('common.free')} color="secondary" />
              )}
            </Box>

            {rollupDeployState.grade === GRADE.mainnet && (
              <Button onClick={handleRollupPlansOpen}>
                {t(keys.deployRollupInfra.changePlan)}
              </Button>
            )}
          </Box>

          {!currentPlan?.settings?.length ? (
            <QueryEmpty />
          ) : (
            <Grid container rowSpacing={5} columnSpacing={7}>
              {currentPlan.settings.map(settingItem => {
                const titleString =
                  typeof settingItem.value === 'boolean'
                    ? settingItem.title
                    : tCommon('common.two-spaced', {
                        first: settingItem.value,
                        second: settingItem.title,
                      });

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`settingItem_${settingItem.uuid}`}
                  >
                    <Box display="flex" gap={1}>
                      <Check color="success" />
                      <Box>
                        <Typography variant="subtitle2" component="div" mb={1}>
                          {titleString}
                        </Typography>
                        <Typography
                          variant="body3"
                          component="div"
                          className={classes.sectionSubtitle}
                        >
                          {settingItem.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      </Card>

      <DeployRollupControlPanel />

      <DeployRollupConfirmModal
        currentPlan={currentPlan}
        rollupDeployState={rollupDeployState}
      />

      {rollupDeployState.grade === GRADE.testnet && (
        <DeployRollupTestnetProveModal rollupDeployState={rollupDeployState} />
      )}
    </>
  );
}
