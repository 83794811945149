/* eslint-disable camelcase */
import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { Gateways, mainApi } from '../../../../api';

interface IGetIsChainIDUsedProps {
  chainID?: number;
}

interface IGetIsChainIDUsedResp {
  used: boolean;
}

export const { useLazyGetIsChainIDUsedQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getIsChainIDUsed: build.query<
      IGetIsChainIDUsedResp,
      IGetIsChainIDUsedProps
    >({
      queryFn: queryFnNotifyWrapper<
        IGetIsChainIDUsedProps,
        never,
        IGetIsChainIDUsedResp
      >(async ({ chainID }) => {
        if (chainID) {
          const { data }: { data: IGetIsChainIDUsedResp } =
            await Gateways.noAuth.api.get(
              `/api/v1/validate/chainid/${chainID}`,
              {
                method: 'GET',
              },
            );

          return { data };
        }
      }),
    }),
  }),
});
