import React, { JSX, useMemo } from 'react';
import { Chip } from '@ankr.com/ui';

import { useTranslation } from '../../../i18n';
import { GRADE } from '../../../Rollup/RollupConst';
import { gradeTranslation } from './translation';

interface IGradeChipProps {
  grade?: GRADE;
  size?: 'small' | 'medium';
  forceColor?: 'primary' | 'secondary';
}

export function GradeChip({
  grade,
  size = 'small',
  forceColor,
}: IGradeChipProps): JSX.Element | null {
  const { keys, t } = useTranslation(gradeTranslation);

  const color = useMemo(() => {
    if (forceColor) {
      return forceColor;
    }
    return grade === GRADE.mainnet ? 'primary' : 'secondary';
  }, [forceColor, grade]);

  if (!grade) {
    return null;
  }

  return (
    <Chip
      label={
        grade === GRADE.mainnet ? t(keys.grade.mainnet) : t(keys.grade.testnet)
      }
      color={color}
      size={size}
    />
  );
}
