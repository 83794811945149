import { makeStyles } from 'tss-react/mui';

export const useListEmptyStyles = makeStyles()(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
    minHeight: 420,
  },
  text: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  buttonsWrap: {
    display: 'flex',
    gap: theme.spacing(3),
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  button: {
    minWidth: 180,
  },
}));
