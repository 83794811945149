import { makeStyles } from 'tss-react/mui';

export const useBillingStyles = makeStyles()(theme => ({
  card: {
    padding: theme.spacing(8),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    justifyContent: 'space-between',
    '&&': {
      borderRadius: 16,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  value: {
    color: theme.palette.text.secondary,
    strong: {
      fontSize: 28,
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
  },
  stackLogo: {
    width: 20,
    height: 20,
  },
  filledBtn: {
    '&, &:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));
